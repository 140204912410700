import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    loginRequest: ['username', 'password'],
    loginSuccess: ['responseData'],
    loginFailure: ['error'],

    logout: null,
  },
  {},
);
