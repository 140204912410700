import { connect } from 'react-redux';

import { Creators as StepCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import BuyPaymentInstruction from './components';

const mapStateToProps = state => {
  return {
    isCheckingMandate: state.buyVignet.isCheckingHasMandate,
    error: state.buyVignet.hasMandateError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    checkMandate: () => {
      dispatch(Creators.buyVignetHasMandateRequest());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuyPaymentInstruction);
