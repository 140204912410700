import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import LayoutPage from './components/LayoutPage';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => {
      dispatch(Creators.logout());
    },
    goToHome: () => {
      history.push('/');
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
