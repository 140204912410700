import React from 'react';
import { Modal } from 'antd';
import ForgotpinForm from './ForgotpinForm';

export const ForgotpinModal = props => {
  const {
    visible,
    onSubmit,
    onCancel,
    intl,
    wasSuccess,
    onChange,
    isLoading,
    isResetPinCodeError,
    resetPinCodeErrorMessage,
  } = props;
  return (
    <Modal
      className="step-one forgot-pin"
      centered
      visible={visible}
      footer={null}
      // maskClosable
      onCancel={onCancel}
    >
      <ForgotpinForm
        onSubmit={onSubmit}
        wasSuccess={wasSuccess}
        onChange={onChange}
        isLoading={isLoading}
        isResetPinCodeError={isResetPinCodeError}
        resetPinCodeErrorMessage={resetPinCodeErrorMessage}
      />
    </Modal>
  );
};

export default ForgotpinModal;
