import React from 'react';
import {
  Row,
  Col,
  Input,
  Modal,
  Checkbox,
  Button,
  Tooltip,
  Icon,
  Form,
  Alert,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './styles.scss';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';

class VignetInfo extends React.Component {
  renderTable = () => {
    return (
      <table border={1} className="vignette-rate">
        <tr>
          <th className="vignette-rate-header">
            Oppervlakte vaartuig (in m<sup>2</sup>)
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.second.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Regulier
            </Tooltip>
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.third.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Milieu
            </Tooltip>
          </th>
          <th className="vignette-rate-header">
            <Tooltip
              title={
                <FormattedMessage id="extend.step.vignette.rate.fourth.hover.message" />
              }
            >
              <Icon type="info-circle" className="info-icon-wrapper" />
              &nbsp; Vignet Abonnement
            </Tooltip>
          </th>
        </tr>
        <tr>
          <td>0 tot 3,5</td>
          <td>&#8364; 95</td>
          <td>&#8364; 28,50</td>
          <td>&#8364; 47,50</td>
        </tr>
        <tr>
          <td>vanaf 3,5 tot 6,0</td>
          <td>&#8364; 74</td>
          <td>&#8364; 22</td>
          <td>&#8364; 37</td>
        </tr>
        <tr>
          <td>vanaf 6,0 tot 9,0</td>
          <td>&#8364; 66</td>
          <td>&#8364; 19,50</td>
          <td>&#8364; 33</td>
        </tr>
        <tr>
          <td>vanaf 9,0 tot 12,5</td>
          <td>&#8364; 61</td>
          <td>&#8364; 18</td>
          <td>&#8364; 30,50</td>
        </tr>
        <tr>
          <td>vanaf 12,5 tot 16,5</td>
          <td>&#8364; 57</td>
          <td>&#8364; 17</td>
          <td>&#8364; 28,50</td>
        </tr>
        <tr>
          <td>vanaf 16,5 en groter</td>
          <td>&#8364; 55</td>
          <td>&#8364; 16,50</td>
          <td>&#8364; 27,50</td>
        </tr>
      </table>
    );
  };
  render() {
    return (
      <StepCard
        onPrevious={() =>
          this.props.updateRoute(BUY_VIGNNETTE_ROUTE.INSTRUCTION_ROUTE)
        }
        onNext={() =>
          this.props.updateRoute(BUY_VIGNNETTE_ROUTE.TARIF_CHOICES_ROUTE)
        }
        current={1}
        steps={7}
        id="buy.vignete.instruction.title"
        className="buy-vignet-info-step"
      >
        <Row className="wrapper">
          <div>
            <p className="title">
              <FormattedMessage id="buy.vignet.info.title" />
            </p>
          </div>
          <Col span={24} xs={24} lg={12} md={24} sm={24}>
            <div className="boat-type-list">
              <Row>
                <Col span={24} xs={24} lg={9} md={24} sm={24}>
                  <img src={require('assets/regulier.png')} />
                </Col>
                <Col span={24} xs={24} lg={14} md={24} sm={24}>
                  <div>
                    <p className="boat-item-title">
                      <FormattedMessage id="regular.boat" />
                    </p>
                    <p>
                      <FormattedMessage id="regular.boat.description" />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="boat-type-list">
              <Row>
                <Col span={24} xs={24} lg={9} md={24} sm={24}>
                  <img src={require('assets/milieu.png')} />
                </Col>
                <Col span={24} xs={24} lg={14} md={24} sm={24}>
                  <div>
                    <p className="boat-item-title">
                      <FormattedMessage id="mileu.boat" />
                    </p>
                    <p>
                      <FormattedMessage id="mileu.boat.description" />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="boat-type-list">
              <Row>
                <Col span={24} xs={24} lg={9} md={24} sm={24}>
                  <img src={require('assets/abonment.png')} />
                </Col>
                <Col span={24} xs={24} lg={14} md={24} sm={24}>
                  <div>
                    <p className="boat-item-title">
                      <FormattedMessage id="abonnement.boat" />
                    </p>
                    <p>
                      <FormattedMessage id="abonnement.boat.description" />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            span={24}
            xs={24}
            lg={12}
            md={24}
            sm={24}
            className="align-to-middle-page"
          >
            {this.renderTable()}
          </Col>
        </Row>
      </StepCard>
    );
  }
}

export default VignetInfo;
