import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { useLanguage } from 'lang';
import Idle from 'react-idle';
import ReactTimeout from 'react-timeout';

import LayoutPage from './containers/Layout';
import ChooseAction from 'containers/ChooseAction';
import ActivateVignette from 'containers/ActivateVignette';
import ActivateVignetteSteps from 'containers/ActivateVignetteSteps';
// import Profile from './containers/Profile';
import { history } from './util/helpers/history';
import InitializeExtension from 'containers/InitializeExtension';
import ExtendVignetteSteps from 'containers/ExtendVignetteSteps';
import BuyVignetteSteps from 'containers/BuyVignetteSteps';
import BuyVignetteChoice from 'containers/BuyVignetteChoice';
import HandleRedirect from 'containers/ExtendVignetteSteps/Steps/Redirect';
import BuyHandleRedirect from 'containers/BuyVignetteSteps/Steps/HandleRedirect';

// ONE DAY
import InitializeDayExtension from 'containers/BuyVignetteSteps/Steps/OneDay/Initialize/components';
import PersonalInfoForm from 'containers/BuyVignetteSteps/Steps/OneDay/Personal';
import OneDayTarifChoices from 'containers/BuyVignetteSteps/Steps/OneDay/Tarif';
import OneDayOverview from 'containers/BuyVignetteSteps/Steps/OneDay/Overview/components';
import OneDayPayment from 'containers/BuyVignetteSteps/Steps/OneDay/Payment';
import OneDaySucess from 'containers/BuyVignetteSteps/Steps/OneDay/Successful/components';

// ANNUAL
import AnnualInstructions from 'containers/BuyVignetteSteps/Steps/Annual/Instructions/components';
import VignetInfo from 'containers/BuyVignetteSteps/Steps/Annual/VignetInfo/components';
import AnnualTarifChoices from 'containers/BuyVignetteSteps/Steps/Annual/TarifChoices';
import ChooseAccount from 'containers/BuyVignetteSteps/Steps/Annual/ChooseAccount/components';
import Login from 'containers/BuyVignetteSteps/Steps/Annual/Login';
import Register from 'containers/BuyVignetteSteps/Steps/Annual/Register';
import AnnualPersonalInfo from 'containers/BuyVignetteSteps/Steps/Annual/Personal';
import AnnualUploadFiles from 'containers/BuyVignetteSteps/Steps/Annual/UploadFiles';
import AnnualOverview from 'containers/BuyVignetteSteps/Steps/Annual/Overview';
import AnnualPaymentInstruction from 'containers/BuyVignetteSteps/Steps/Annual/PaymentInstruction';
import AnnualMandate from 'containers/BuyVignetteSteps/Steps/Annual/Mandate';
import AnnualPaymentChoice from 'containers/BuyVignetteSteps/Steps/Annual/PaymentChoice';
import AnnualDirect from 'containers/BuyVignetteSteps/Steps/Annual/Direct';
import AnnualSuccess from 'containers/BuyVignetteSteps/Steps/Annual/Success/components';
import AnnualResetPassword from 'containers/BuyVignetteSteps/Steps/Annual/ResetPassword';
import { logOut } from 'util/helpers/normalizer';

let winNav = window.navigator;
let isOpera = typeof window.opr !== 'undefined';

let msie = winNav.userAgent.indexOf('MSIE ');
let isInternetExplorer =
  msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

export default () => {
  const [language, messages] = useLanguage();
  return (
    <IntlProvider locale={language} messages={messages}>
      <Idle
        timeout={1000 * 60 * 15}
        onChange={inActive => {
          if (inActive) {
            logOut();
            window.location.href = '/';
          }
        }}
      />
      {isInternetExplorer || isOpera ? (
        <p className="browser-not-supported">
          <FormattedMessage id="browser.not.supported" />
        </p>
      ) : (
        <Router history={history}>
          <Switch>
            <LayoutPage>
              {/*<Route path="/" exact component={Auth} />*/}
              <Route
                exact
                path="/:vignette"
                component={({ match }) => {
                  return (
                    <Redirect
                      to={{
                        pathname: `/activate/vignette/steps`,
                        state: { vignette: match.params.vignette },
                      }}
                    />
                  );
                }}
              />
              <Route path="/" exact component={ChooseAction} />
              <Route
                path="/activate/vignette"
                exact
                component={ActivateVignette}
              />
              <Route
                path="/activate/vignette/steps"
                exact
                component={ActivateVignetteSteps}
              />
              <Route
                path="/extend/vignette"
                exact
                component={InitializeExtension}
              />
              <Route
                path="/extend/vignette/steps"
                exact
                component={ExtendVignetteSteps}
              />
              <Route
                path="/buy/vignette/steps"
                exact
                component={BuyVignetteSteps}
              />
              <Route path="/buy/vignette" exact component={BuyVignetteChoice} />

              {/* ONE DAY VIGNETTE */}
              <Route
                path="/buy/vignette/one-day/"
                exact
                component={InitializeDayExtension}
              />
              <Route
                path="/buy/vignette/one-day/personal"
                exact
                component={PersonalInfoForm}
              />
              <Route
                path="/buy/vignette/one-day/tarif"
                exact
                component={OneDayTarifChoices}
              />
              <Route
                path="/buy/vignette/one-day/overview/:status/"
                component={OneDayOverview}
              />
              <Route
                path="/buy/vignette/one-day/payment"
                exact
                component={OneDayPayment}
              />
              <Route
                path="/buy/vignette/one-day/success"
                exact
                component={OneDaySucess}
              />

              {/* ANNUAL ROUTE */}
              <Route
                path="/buy/vignette/annual"
                exact
                component={AnnualInstructions}
              />
              <Route
                path="/buy/vignette/annual/vignet-info"
                exact
                component={VignetInfo}
              />
              <Route
                path="/buy/vignette/annual/tarif"
                exact
                component={AnnualTarifChoices}
              />
              <Route
                path="/buy/vignette/annual/choose-account"
                exact
                component={ChooseAccount}
              />
              <Route
                path="/buy/vignette/annual/login"
                exact
                component={Login}
              />
              <Route
                path="/buy/vignette/annual/reset/:token"
                exact
                component={AnnualResetPassword}
              />
              <Route
                path="/buy/vignette/annual/register"
                exact
                component={Register}
              />
              <Route
                path="/buy/vignette/annual/personal"
                exact
                component={AnnualPersonalInfo}
              />
              <Route
                path="/buy/vignette/annual/upload-files"
                exact
                component={AnnualUploadFiles}
              />
              <Route
                path="/buy/vignette/annual/overview"
                exact
                component={AnnualOverview}
              />
              <Route
                path="/buy/vignette/annual/payment-instruction/:type"
                exact
                component={AnnualPaymentInstruction}
              />
              <Route
                path="/buy/vignette/annual/mandate"
                exact
                component={AnnualMandate}
              />
              <Route
                path="/buy/vignette/annual/payment-choice/:type"
                exact
                component={AnnualPaymentChoice}
              />
              <Route
                path="/buy/vignette/annual/direct/:type"
                exact
                component={AnnualDirect}
              />
              <Route
                path="/buy/vignette/annual/success"
                exact
                component={AnnualSuccess}
              />

              <Route
                path="/extend/vignette/payment/:type/"
                exact
                component={HandleRedirect}
              />
              <Route
                path="/buy/vignette/payment/:type/"
                exact
                component={BuyHandleRedirect}
              />
              {/*<AuthenticatedRoute path="/one" component={Profile} />*/}
            </LayoutPage>
          </Switch>
        </Router>
      )}
    </IntlProvider>
  );
};
