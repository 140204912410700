import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualDirect from './components';

const mapStateToProps = state => {
  return {
    banks: state.buyVignet.banks,
    isFetchingBanks: state.buyVignet.isGettingBanks,
    isMakingAnnualPayment: state.buyVignet.creatingDirect,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBankLists: data => {
      dispatch(Creators.buyVignetGetBankListsRequest(data));
    },
    createDirect: issuer => {
      dispatch(Creators.buyVignetCreateDirectRequest(issuer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnualDirect);
