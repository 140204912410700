import React from 'react';
import { history } from 'util/helpers/history';
import DirectPaymentBank from 'containers/ExtendVignetteSteps/Steps/DirectPaymentBank/components';

const OneDayPayment = props => {
  return (
    <DirectPaymentBank
      current={3}
      steps={4}
      id="buy.vignet.instruction.one.day.title"
      getBankLists={() => props.getBankLists({ list_type: 'ideal' })}
      banks={props.banks}
      isFetchingBanks={props.isFetchingBanks}
      isNextLoading={props.isMakingDayPayment}
      postDirect={data => props.makeDayPayment(data)}
      onPrevious={() => history.goBack()}
      error={props.makingDayPaymentError}
    />
  );
};

export default OneDayPayment;
