import { apiRequest } from '../apiRequest';
import {
  GET_TARIF_CHOICES_ENDPOINT,
  BUY_VIGNET_SIGN_IN_ENDPOINT,
  BUY_VIGNET_CREATE_ACCOUNT_ENDPOINT,
  BUY_VIGNET_GET_OWNER,
  BUY_VIGNET_UPDATE_OWNER,
  GET_OBJECT_TYPES,
  GET_DRIVE_TYPES,
  CREATE_DAY_REQUEST,
  GET_BANK_LIST,
  MAKE_DAY_PAYMENT,
  GET_OVERVIEW,
  UPLOAD_DOCUMENTS,
  BUY_HAS_MANDATE,
  BUY_CREATE_MANDATE,
  BUY_GET_INSTALLMENTS,
  BUY_GET_VIGNET_COST,
  BUY_CREATE_DIRECT_PAYMENT,
  BUY_CREATE_INSTALLMENTS,
  BUY_CHECK_DAY_REQUEST,
  BUY_CONFIRMATION,
  BUY_FORGOT_PASSWORD,
  BUY_RESET_PASSWORD,
} from '../urls';

export const buyVigneteGetTarifChoices = data => {
  return apiRequest('POST', GET_TARIF_CHOICES_ENDPOINT, data);
};

export const buyVigneteLogin = data => {
  return apiRequest('POST', BUY_VIGNET_SIGN_IN_ENDPOINT, data);
};

export const buyVigneteCreateAccount = data => {
  return apiRequest('POST', BUY_VIGNET_CREATE_ACCOUNT_ENDPOINT, data);
};

export const buyVigneteGetOwner = () => {
  return apiRequest('GET', BUY_VIGNET_GET_OWNER, null);
};

export const buyVignetUpdateOwner = data => {
  return apiRequest('POST', BUY_VIGNET_UPDATE_OWNER, data);
};

export const buyGetObjectTypes = () => {
  return apiRequest('GET', GET_OBJECT_TYPES, null);
};

export const buyGetDriveTypes = () => {
  return apiRequest('GET', GET_DRIVE_TYPES, null);
};

export const buyVignetCreateDayRequest = data => {
  return apiRequest('POST', CREATE_DAY_REQUEST, data);
};

export const buyGetBankLists = data => {
  return apiRequest('POST', GET_BANK_LIST, data);
};

export const buyMakeDayPayment = data => {
  return apiRequest('POST', MAKE_DAY_PAYMENT, data);
};

export const buyGetOverview = () => {
  return apiRequest('GET', GET_OVERVIEW, null);
};

export const buyUploadDocuments = data => {
  return apiRequest('POST', UPLOAD_DOCUMENTS, data);
};

export const buyHasMandate = () => {
  return apiRequest('GET', BUY_HAS_MANDATE, null);
};

export const buyCreateMandate = data => {
  return apiRequest('POST', BUY_CREATE_MANDATE, data);
};

export const buyGetInstallments = () => {
  return apiRequest('GET', BUY_GET_INSTALLMENTS, null);
};

export const buyGetVignetCost = () => {
  return apiRequest('GET', BUY_GET_VIGNET_COST, null);
};

export const buyCreateDirect = data => {
  return apiRequest('POST', BUY_CREATE_DIRECT_PAYMENT, data);
};

export const buyCreateInstallment = data => {
  return apiRequest('POST', BUY_CREATE_INSTALLMENTS, data);
};

export const buyCheckDayRequest = data => {
  return apiRequest('POST', BUY_CHECK_DAY_REQUEST, data);
};

export const buyGetVignetConfirmation = () => {
  return apiRequest('GET', BUY_CONFIRMATION, null);
};

export const buyVignetForgotPassword = data => {
  return apiRequest('POST', BUY_FORGOT_PASSWORD, data);
};

export const buyVignetResetPassword = data => {
  return apiRequest('POST', BUY_RESET_PASSWORD, data);
};
