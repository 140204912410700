import './index.scss';
import React, { Component } from 'react';
// import InputField from '../../../components/InputField';
// import { required } from '../../../utils/validations';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Steps, Row, Col, Card, Button, Icon } from 'antd';
import ActivationSteps from './Steps';
import Stepper from 'components/Steppers';
import Title from 'components/Title';

class ActivateVignetteSteps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // starts from 0, and 0 =/is first step to be one in UI
      currentStep: 0,
    };
  }

  changeCurrentStep = (currentStep = 0) => {
    if (currentStep === -1 || currentStep === ActivationSteps.length) {
      return;
    }
    this.setState({
      currentStep,
    });
  };

  prevStep = () => {
    if (this.state.currentStep === 0) {
      this.props.gotToActivateVignette();
      return;
    }
    this.changeCurrentStep(this.state.currentStep - 1);
  };

  nextStep = () => {
    const currentStep = this.state.currentStep + 1;
    if (currentStep === ActivationSteps.length) {
      this.props.gotToHome();
      return;
    }
    this.changeCurrentStep(currentStep);
  };

  render() {
    const StepComponent = ActivationSteps[this.state.currentStep];

    return (
      <div className="activate-vignette-steps">
        <StepComponent
          prevStep={this.prevStep}
          nextStep={this.nextStep}
          steps={ActivationSteps.length}
          current={this.state.currentStep}
        />
      </div>
    );
  }
}

ActivateVignetteSteps.propTypes = {
  isloading: PropTypes.bool,
  // goToActivateVignette: PropTypes.func.isRequired,
  // goToExtendVignette: PropTypes.func.isRequired,
};

// you can use formik or redux forms

export default ActivateVignetteSteps;
