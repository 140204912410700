import { connect } from 'react-redux';

import { Creators as StepCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import Overview from './components';

const mapStateToProps = state => {
  return {
    isGettingOverview: state.buyVignet.isGettingOverview,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    getOverview: () => {
      dispatch(Creators.buyVignetGetOverviewRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
