import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualUploadFiles from './components';

const mapStateToProps = state => {
  return {
    isLoading: state.buyVignet.uploadingDocuments,
    error: state.buyVignet.uploadDocumentsError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadDocuments: data => {
      dispatch(Creators.buyVignetUploadDocumentsRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnualUploadFiles);
