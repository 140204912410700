import { call, put, takeLeading } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/extendVerification/actions';
import {
  extendVerifyVignet as extendVerifyVignetAPI,
  extendGetVignetCost as extendGetVignetCostAPI,
} from '../../api/extendVerification';

export function* extendVerifyVignet(actions) {
  try {
    const { vignet_number, bsn } = actions;
    const response = yield call(extendVerifyVignetAPI, { vignet_number, bsn });
    yield put(Creators.extendVerificationSuccess(response.data));
    yield put(Creators.extendGetVignetCostRequest(response.data.vignet_id));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendVerificationFailure(error_message));
  }
}

export function* extendGetVignetCost(actions) {
  try {
    const { vignet_id } = actions;
    const response = yield call(extendGetVignetCostAPI, { vignet_id });
    yield put(Creators.extendGetVignetCostSuccess(response.data));
    sessionStorage.setItem('cost', JSON.stringify(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.extendGetVignetCostFailure(error_message));
  }
}

export function* watchExtendVerifyVignet() {
  yield takeLeading(Types.EXTEND_VERIFICATION_REQUEST, extendVerifyVignet);
}

export function* watchExtendGetVignetCost() {
  yield takeLeading(Types.EXTEND_GET_VIGNET_COST_REQUEST, extendGetVignetCost);
}
