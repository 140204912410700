import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    checkPinCodeRequest: ['vignet_number', 'pin'],
    checkPinCodeSuccess: ['responseData'],
    checkPinCodeFailure: ['error'],

    resetPinCodeRequest: ['vignet_number'],
    resetPinCodeSuccess: ['responseData'],
    resetPinCodeFailure: ['error'],

    getVesselTypesRequest: [],
    getVesselTypesSuccess: ['responseData'],
    getVesselTypesFailure: ['error'],

    getVesselDetailsRequest: ['object_id'],
    getVesselDetailsSuccess: ['responseData'],
    getVesselDetailsFailure: ['error'],

    updateVesselRequest: ['object_name', 'object_type'],
    updateVesselSuccess: ['responseData'],
    updateVesselFailure: ['error'],

    uploadVesselPicturesRequest: ['distant_photo', 'detail_photo'],
    uploadVesselPicturesSuccess: ['responseData'],
    uploadVesselPicturesFailure: ['error'],

    // to be called on mount of success step, to reset/clear state
    onCompleteClear: [],
  },
  {},
);
