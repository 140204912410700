import { connect } from 'react-redux';

import { Creators as StepCreators } from 'services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import TarifChoices from './components';

const mapStateToProps = state => {
  return {
    isRequesting: state.buyVignet.isGettingTarifChoices,
    data: state.buyVignet.tarifChoices,
    error: state.buyVignet.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    getTarifChoices: data => {
      dispatch(Creators.buyVignetGetTarifChoicesRequest(data));
    },
    clearData: () => {
      dispatch(Creators.buyVignetClearTarifChoices());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TarifChoices);
