import { combineReducers } from 'redux';

import auth from 'services/redux/auth/reducers';
import app from 'services/redux/app/reducers';
import extendVignet from 'services/redux/extendVerification/reducers';
import vignette from 'services/redux/vignette/reducers';
import extendCancelVignette from 'services/redux/extendCancelVignette/reducers';
import extendVignetteSteps from 'services/redux/extendVignetteSteps/reducers';
import extendVignetData from 'services/redux/extendVignette/reducers';
import buyVignet from 'services/redux/buyVignette/reducers';

const rootReducer = combineReducers({
  app,
  auth,
  vignette,
  extendVignet,
  extendCancelVignette,
  extendVignetteSteps,
  extendVignetData,
  buyVignet,
});

export default rootReducer;
