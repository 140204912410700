import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    cancelVignetRequest: ['email'],
    cancelVignetSuccess: ['responseData'],
    cancelVignetFailure: ['error'],
  },
  {},
);
