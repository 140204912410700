import { connect } from 'react-redux';

import InitializeExtension from './components';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    goToExtendVerification: () => {
      history.push('/extend/vignette/steps');
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InitializeExtension);
