import { apiRequest } from '../apiRequest';
import {
  CHECK_PIN_CODE,
  RESET_PIN_CODE,
  VESSEL_TYPES,
  VESSEL_DETAILS,
  UPDATE_VESSEL,
  GET_OWNER_DETAILS,
  UPDATE_OWNER_DETAILS,
  UPLOAD_VESSEL_PICTURES,
} from '../urls';

export const checkPinCode = data => {
  return apiRequest('POST', CHECK_PIN_CODE, data);
};

export const resetPinCode = data => {
  return apiRequest('POST', RESET_PIN_CODE, data);
};

export const getVesselTypes = ({ access_token }) => {
  return apiRequest('GET', VESSEL_TYPES, null, access_token);
};

export const getVesselDetails = ({ object_id, access_token }) => {
  return apiRequest('POST', VESSEL_DETAILS, { object_id }, access_token);
};

export const updateVessel = ({ data, access_token }) => {
  return apiRequest('POST', UPDATE_VESSEL, data, access_token);
};

export const uploadVesselPictures = ({ data, access_token }) => {
  return apiRequest('POST', UPLOAD_VESSEL_PICTURES, data, access_token);
};
