import { call, put, takeLeading, select } from 'redux-saga/effects';
import { Creators, Types } from '../../redux/vignette/actions';
import {
  checkPinCode as checkPinCodeApi,
  resetPinCode as resetPinCodeApi,
  getVesselTypes as getVesselTypesApi,
  updateVessel as updateVesselApi,
  getVesselDetails as getVesselDetailsApi,
  uploadVesselPictures as uploadVesselPicturesApi,
} from '../../api/vignette';

export function* checkPinCode(actions) {
  try {
    const { vignet_number, pin } = actions;
    const response = yield call(checkPinCodeApi, { vignet_number, pin });
    yield put(Creators.checkPinCodeSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.checkPinCodeFailure(error_message));
  }
}

export function* watchCheckPingCode() {
  yield takeLeading(Types.CHECK_PIN_CODE_REQUEST, checkPinCode);
}

export function* resetPinCode(actions) {
  try {
    const { vignet_number } = actions;
    const response = yield call(resetPinCodeApi, { vignet_number });
    yield put(Creators.resetPinCodeSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.resetPinCodeFailure(error_message));
  }
}

export function* watchResetPingCode() {
  yield takeLeading(Types.RESET_PIN_CODE_REQUEST, resetPinCode);
}

export function* getVesselTypes(actions) {
  try {
    const { vignet_number } = actions;

    let access_token = yield select(({ vignette }) => {
      return (vignette.checkPinCodeResult || {}).access_token || '';
    });

    const response = yield call(getVesselTypesApi, { access_token });
    yield put(Creators.getVesselTypesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getVesselTypesFailure(error_message));
  }
}

export function* watchGetVesselTypes() {
  yield takeLeading(Types.GET_VESSEL_TYPES_REQUEST, getVesselTypes);
}

export function* getVesselDetails(actions) {
  try {
    let [object_id, access_token] = yield select(({ vignette: data }) => {
      const vignette = data.checkPinCodeResult || {};
      return [vignette.object_id, vignette.access_token];
    });

    const response = yield call(getVesselDetailsApi, {
      object_id,
      access_token,
    });
    yield put(Creators.getVesselDetailsSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.getVesselDetailsFailure(error_message));
  }
}

export function* watchGetVesselDetails() {
  yield takeLeading(Types.GET_VESSEL_DETAILS_REQUEST, getVesselDetails);
}

export function* updateVessel(actions) {
  try {
    const { object_name, object_type } = actions;

    let data = { object_name, object_type };

    let [object_id, access_token] = yield select(({ vignette: data }) => {
      const vignette = data.checkPinCodeResult || {};
      return [vignette.object_id, vignette.access_token];
    });
    data.object_id = object_id;

    const response = yield call(updateVesselApi, { data, access_token });
    yield put(Creators.updateVesselSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.updateVesselFailure(error_message));
  }
}

export function* watchUpdateVessel() {
  yield takeLeading(Types.UPDATE_VESSEL_REQUEST, updateVessel);
}

export function* uploadVesselPictures(actions) {
  try {
    const { distant_photo, detail_photo } = actions;

    let data = {
      distant_photo,
      detail_photo,
    };

    let [object_id, access_token] = yield select(({ vignette: data }) => {
      const vignette = data.checkPinCodeResult || {};
      return [vignette.object_id, vignette.access_token];
    });
    data.object_id = object_id;

    const response = yield call(uploadVesselPicturesApi, {
      data,
      access_token,
    });
    yield put(Creators.uploadVesselPicturesSuccess(response.data));
  } catch (error) {
    let error_message =
      error && error.response && error.response.data.errors.non_field_errors;
    yield put(Creators.uploadVesselPicturesFailure(error_message));
  }
}

export function* watchUploadVesselPictures() {
  yield takeLeading(Types.UPLOAD_VESSEL_PICTURES_REQUEST, uploadVesselPictures);
}
