import { createReducer } from 'reduxsauce';
import Types from './actionTypes';
import { normalizeData } from 'util/helpers/normalizer';

export const INITIAL_STATE = {
  isGettingTarifChoices: false,
  isLoginIn: false,
  isCreatingAccount: false,
  isGettingOwner: false,
  isUpdatingOwner: false,
  isCreatingDay: false,
  createDayError: null,
  isGettingBanks: false,
  gettingBanksError: null,
  isMakingDayPayment: false,
  makingDayPaymentError: null,
  isGettingOverview: false,
  getOverviewError: null,
  uploadingDocuments: false,
  uploadedDocuments: {},
  isCheckingHasMandate: false,
  hasMandate: null,
  hasMandateError: null,
  isCreatingMandate: false,
  createMandateError: null,
  isGettingInstallment: false,
  installments: [],
  installmentError: null,
  isGettingVignetCost: false,
  vignetCost: null,
  vignetCostError: null,
  creatingDirect: false,
  createDirectError: null,
  creatingInstallment: false,
  createInstallmentError: null,
  checkingDay: false,
  checkDayError: false,
  confirmation: false,
  forgettingPassword: false,
  forgetPasswordError: null,
  sentForgotPassword: false,
  resettingPassword: false,
  resetPasswordError: null,
  overview: {},
  owner: {},
  driveTypes: [],
  objectTypes: [],
  banks: [],
  token: null,
  tarifChoices: null,
  makingDayPaymentUrl: null,
  error: null,
};

export const buyVignetGetTarifChoicesRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isGettingTarifChoices: true,
    error: null,
  };
};

export const buyVignetGetTarifChoicesVignetSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  sessionStorage.setItem('tarifChoices', JSON.stringify(action.responseData));
  return {
    ...state,
    isGettingTarifChoices: false,
    error: null,
    tarifChoices: action.responseData,
  };
};

export const buyVignetGetTarifChoicesVignetFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isGettingTarifChoices: false,
    tarifChoices: null,
    error: action.error,
  };
};

export const buyVignetClearTarifChoicesVignet = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    tarifChoices: null,
  };
};

export const logInRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoginIn: true,
    loginError: null,
  };
};

export const logInSuccess = (state = INITIAL_STATE, action) => {
  sessionStorage.setItem('access_token', action.responseData.access_token);
  return {
    ...state,
    isLoginIn: false,
    loginError: null,
    token: action.responseData.access_token,
  };
};

export const logInFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoginIn: false,
    token: null,
    loginError: action.error,
  };
};

export const createAccountRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingAccount: true,
    accountError: null,
  };
};

export const createAccountSuccess = (state = INITIAL_STATE, action) => {
  sessionStorage.setItem('access_token', action.responseData.access_token);
  return {
    ...state,
    isCreatingAccount: false,
    accountError: null,
    token: action.responseData.access_token,
  };
};

export const createAccountFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingAccount: false,
    token: null,
    accountError: action.error,
  };
};

export const getOwnerRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingOwner: true,
    error: null,
  };
};

export const getOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingOwner: false,
    error: null,
    owner: action.responseData,
  };
};

export const getOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingOwner: false,
    owner: {},
    error: action.error,
  };
};

export const updateOwnerRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingOwner: true,
    updateError: null,
  };
};

export const updateOwnerSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingOwner: false,
    updateError: null,
  };
};

export const updateOwnerFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingOwner: false,
    updateError: action.error,
  };
};

export const getObjectTypesRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingObjectTypes: true,
    error: null,
  };
};

export const getObjectTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingObjectTypes: false,
    error: null,
    objectTypes: action.responseData.object_types,
  };
};

export const getObjectTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingObjectTypes: false,
    error: action.error,
  };
};

export const getDriveTypesRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: true,
    error: null,
  };
};

export const getDriveTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: false,
    error: null,
    driveTypes: action.responseData.drive_types,
  };
};

export const getDriveTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingDriveTypes: false,
    error: action.error,
  };
};

export const createDayRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingDay: true,
    createDayError: null,
  };
};

export const createDaySuccess = (state = INITIAL_STATE, action) => {
  sessionStorage.setItem('access_token', action.responseData.access_token);
  return {
    ...state,
    isCreatingDay: false,
    createDayError: null,
    token: action.responseData.access_token,
  };
};

export const createDayFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingDay: false,
    createDayError: action.error,
  };
};

export const getBanksRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingBanks: true,
    gettingBanksError: null,
  };
};

export const getBanksSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingBanks: false,
    gettingBanksError: null,
    banks: normalizeData(action.responseData),
  };
};

export const getBanksFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingBanks: false,
    gettingBanksError: action.error,
  };
};

export const makeDayPaymentRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingDayPayment: true,
    makingDayPaymentError: null,
  };
};

export const makeDayPaymentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingDayPayment: false,
    makingDayPaymentError: null,
    makingDayPaymentUrl: action.responseData.redirect_url,
  };
};

export const makeDayPaymentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isMakingDayPayment: false,
    makingDayPaymentError: action.error,
  };
};

export const getOverviewRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingOverview: true,
    getOverviewError: null,
  };
};

export const getOverviewSuccess = (state = INITIAL_STATE, action) => {
  const tarif = JSON.parse(sessionStorage.getItem('tarifChoices'));
  const data = { ...action.responseData, surface: tarif.surface };
  return {
    ...state,
    isGettingOverview: false,
    getOverviewError: null,
    overview: data,
  };
};

export const getOverviewFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingOverview: false,
    getOverviewError: action.error,
  };
};

export const uploadDocumentsRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocuments: true,
    uploadDocumentsError: null,
  };
};

export const uploadDocumentsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocuments: false,
    uploadDocumentsError: null,
    uploadedDocuments: action.responseData,
  };
};

export const uploadDocumentsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    uploadingDocuments: false,
    uploadDocumentsError: action.error,
  };
};

//
export const hasMandateRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingHasMandate: true,
    hasMandateError: null,
  };
};

export const hasMandateSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingHasMandate: false,
    hasMandateError: null,
    hasMandate: action.responseData.has_mandate,
  };
};

export const hasMandateFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingHasMandate: false,
    hasMandateError: action.error,
  };
};

export const createMandateRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingMandate: true,
    createMandateError: null,
  };
};

export const createMandateSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingMandate: false,
    createMandateError: null,
  };
};

export const createMandateFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCreatingMandate: false,
    createMandateError: action.error,
  };
};

//
export const getInstallmentRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingInstallment: true,
    installmentError: null,
  };
};

export const getInstallmentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingInstallment: false,
    installments: action.responseData,
    installmentError: null,
  };
};

export const getInstallmentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingInstallment: false,
    installmentError: action.error,
  };
};

export const getVignetCostRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignetCost: true,
    vignetCostError: null,
  };
};

export const getVignetCostSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignetCost: false,
    vignetCost: action.responseData,
    vignetCostError: null,
  };
};

export const getVignetCostFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVignetCost: false,
    vignetCostError: action.error,
  };
};

export const createDirectRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingDirect: true,
    createDirectError: null,
  };
};

export const createDirectSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingDirect: false,
    createDirect: action.responseData,
    createDirectError: null,
  };
};

export const createDirectFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingDirect: false,
    createDirectError: action.error,
  };
};

export const createInstallmentRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingInstallment: true,
    createInstallmentError: null,
  };
};

export const createInstallmentSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingInstallment: false,
    createInstallment: action.responseData,
    createInstallmentError: null,
  };
};

export const createInstallmentFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    creatingInstallment: false,
    createInstallmentError: action.error,
  };
};

//
export const checkDayRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingDay: true,
    checkDayError: null,
  };
};

export const checkDaySuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingDay: false,
    checkDayError: null,
  };
};

export const checkDayFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    checkingDay: false,
    checkDayError: action.error,
  };
};
//
export const confirmingRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    confirmation: true,
  };
};

export const confirmingSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    confirmation: false,
  };
};

export const confirmingFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    confirmation: false,
  };
};

export const forgotPasswordRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    forgettingPassword: true,
    forgetPasswordError: null,
  };
};

export const forgotPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    forgettingPassword: false,
    forgetPasswordError: null,
    sentForgotPassword: true,
  };
};

export const forgotPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    forgettingPassword: false,
    forgetPasswordError: action.error,
  };
};

export const resetPasswordRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resettingPassword: true,
    resetPasswordError: null,
  };
};

export const resetPasswordSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resettingPassword: false,
    resetPasswordError: null,
  };
};

export const resetPasswordFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    resettingPassword: false,
    resetPasswordError: action.error,
  };
};

export const HANDLERS = {
  [Types.BUY_VIGNET_GET_TARIF_CHOICES_REQUEST]: buyVignetGetTarifChoicesRequest,
  [Types.BUY_VIGNET_GET_TARIF_CHOICES_SUCCESS]: buyVignetGetTarifChoicesVignetSuccess,
  [Types.BUY_VIGNET_GET_TARIF_CHOICES_FAILURE]: buyVignetGetTarifChoicesVignetFailure,

  [Types.BUY_VIGNET_CLEAR_TARIF_CHOICES]: buyVignetClearTarifChoicesVignet,

  [Types.BUY_VIGNET_LOGIN_REQUEST]: logInRequest,
  [Types.BUY_VIGNET_LOGIN_SUCCESS]: logInSuccess,
  [Types.BUY_VIGNET_LOGIN_FAILURE]: logInFailure,

  [Types.BUY_VIGNET_CREATE_ACCOUNT_REQUEST]: createAccountRequest,
  [Types.BUY_VIGNET_CREATE_ACCOUNT_SUCCESS]: createAccountSuccess,
  [Types.BUY_VIGNET_CREATE_ACCOUNT_FAILURE]: createAccountFailure,

  [Types.BUY_VIGNET_GET_OWNER_REQUEST]: getOwnerRequest,
  [Types.BUY_VIGNET_GET_OWNER_SUCCESS]: getOwnerSuccess,
  [Types.BUY_VIGNET_GET_OWNER_FAILURE]: getOwnerFailure,

  [Types.BUY_VIGNET_UPDATE_OWNER_REQUEST]: updateOwnerRequest,
  [Types.BUY_VIGNET_UPDATE_OWNER_SUCCESS]: updateOwnerSuccess,
  [Types.BUY_VIGNET_UPDATE_OWNER_FAILURE]: updateOwnerFailure,

  [Types.BUY_VIGNET_GET_OBJECT_TYPES_REQUEST]: getObjectTypesRequest,
  [Types.BUY_VIGNET_GET_OBJECT_TYPES_SUCCESS]: getObjectTypesSuccess,
  [Types.BUY_VIGNET_GET_OBJECT_TYPES_FAILURE]: getObjectTypesFailure,

  [Types.BUY_VIGNET_GET_DRIVE_TYPES_REQUEST]: getDriveTypesRequest,
  [Types.BUY_VIGNET_GET_DRIVE_TYPES_SUCCESS]: getDriveTypesSuccess,
  [Types.BUY_VIGNET_GET_DRIVE_TYPES_FAILURE]: getDriveTypesFailure,

  [Types.BUY_VIGNET_CREATE_DAY_REQUEST]: createDayRequest,
  [Types.BUY_VIGNET_CREATE_DAY_SUCCESS]: createDaySuccess,
  [Types.BUY_VIGNET_CREATE_DAY_FAILURE]: createDayFailure,

  [Types.BUY_VIGNET_GET_BANK_LISTS_REQUEST]: getBanksRequest,
  [Types.BUY_VIGNET_GET_BANK_LISTS_SUCCESS]: getBanksSuccess,
  [Types.BUY_VIGNET_GET_BANK_LISTS_FAILURE]: getBanksFailure,

  [Types.BUY_VIGNET_MAKE_DAY_PAYMENT_REQUEST]: makeDayPaymentRequest,
  [Types.BUY_VIGNET_MAKE_DAY_PAYMENT_SUCCESS]: makeDayPaymentSuccess,
  [Types.BUY_VIGNET_MAKE_DAY_PAYMENT_FAILURE]: makeDayPaymentFailure,

  [Types.BUY_VIGNET_GET_OVERVIEW_REQUEST]: getOverviewRequest,
  [Types.BUY_VIGNET_GET_OVERVIEW_SUCCESS]: getOverviewSuccess,
  [Types.BUY_VIGNET_GET_OVERVIEW_FAILURE]: getOverviewFailure,

  [Types.BUY_VIGNET_UPLOAD_DOCUMENTS_REQUEST]: uploadDocumentsRequest,
  [Types.BUY_VIGNET_UPLOAD_DOCUMENTS_SUCCESS]: uploadDocumentsSuccess,
  [Types.BUY_VIGNET_UPLOAD_DOCUMENTS_FAILURE]: uploadDocumentsFailure,

  [Types.BUY_VIGNET_HAS_MANDATE_REQUEST]: hasMandateRequest,
  [Types.BUY_VIGNET_HAS_MANDATE_SUCCESS]: hasMandateSuccess,
  [Types.BUY_VIGNET_HAS_MANDATE_FAILURE]: hasMandateFailure,

  [Types.BUY_VIGNET_CREATE_MANDATE_REQUEST]: createMandateRequest,
  [Types.BUY_VIGNET_CREATE_MANDATE_SUCCESS]: createMandateSuccess,
  [Types.BUY_VIGNET_CREATE_MANDATE_FAILURE]: createMandateFailure,

  [Types.BUY_VIGNET_GET_INSTALLMENTS_REQUEST]: getInstallmentRequest,
  [Types.BUY_VIGNET_GET_INSTALLMENTS_SUCCESS]: getInstallmentSuccess,
  [Types.BUY_VIGNET_GET_INSTALLMENTS_FAILURE]: getInstallmentFailure,

  [Types.BUY_VIGNET_GET_VIGNET_COST_REQUEST]: getVignetCostRequest,
  [Types.BUY_VIGNET_GET_VIGNET_COST_SUCCESS]: getVignetCostSuccess,
  [Types.BUY_VIGNET_GET_VIGNET_COST_FAILURE]: getVignetCostFailure,

  [Types.BUY_VIGNET_CREATE_DIRECT_REQUEST]: createDirectRequest,
  [Types.BUY_VIGNET_CREATE_DIRECT_SUCCESS]: createDirectSuccess,
  [Types.BUY_VIGNET_CREATE_DIRECT_FAILURE]: createDirectFailure,

  [Types.BUY_VIGNET_CREATE_INSTALLMENT_REQUEST]: createInstallmentRequest,
  [Types.BUY_VIGNET_CREATE_INSTALLMENT_SUCCESS]: createInstallmentSuccess,
  [Types.BUY_VIGNET_CREATE_INSTALLMENT_FAILURE]: createInstallmentFailure,

  [Types.BUY_VIGNET_CHECK_DAY_REQUEST]: checkDayRequest,
  [Types.BUY_VIGNET_CHECK_DAY_SUCCESS]: checkDaySuccess,
  [Types.BUY_VIGNET_CHECK_DAY_FAILURE]: checkDayFailure,

  [Types.BUY_VIGNET_CONFIRMATION_REQUEST]: confirmingRequest,
  [Types.BUY_VIGNET_CONFIRMATION_SUCCESS]: confirmingSuccess,
  [Types.BUY_VIGNET_CONFIRMATION_FAILURE]: confirmingFailure,

  [Types.BUY_VIGNET_FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [Types.BUY_VIGNET_FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [Types.BUY_VIGNET_FORGOT_PASSWORD_FAILURE]: forgotPasswordFailure,

  [Types.BUY_VIGNET_RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.BUY_VIGNET_RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [Types.BUY_VIGNET_RESET_PASSWORD_FAILURE]: resetPasswordFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
