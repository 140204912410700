import React from 'react';
import Instruction from 'components/Instruction';
import { FormattedMessage } from 'react-intl';

const InitializeExtension = props => {
  return (
    <Instruction
      header={'buy.vignete.instruction.title'}
      content={'buy.vignete.instruction.content'}
      subheader={'buy.vignet.instruction.subtitle'}
      messages={[
        'buy.vinget.instruction.item.1',
        'buy.vinget.instruction.item.2',
        'buy.vinget.instruction.item.3',
        'buy.vinget.instruction.item.4',
        'buy.vinget.instruction.item.5',
        'buy.vinget.instruction.item.6',
        'buy.vinget.instruction.item.7',
      ]}
      // description={{
      //   title: 'activate.instructions.desc.title',
      //   bullets: [
      //     'buy.vignete.instruction.title',
      //     'buy.vignete.instruction.title',
      //     'buy.vignete.instruction.title',
      //   ],
      // }}
      iconURL={require('assets/timer_15.svg')}
      iconStyle={{ height: 120, width: 120 }}
      onStart={() => props.goToVignetteInfo()}
      // contact={'buy.vignet.instruction.contact'}
    />
  );
};

export const InitializeDayExtension = props => {
  return (
    <Instruction
      header={'buy.vignet.instruction.one.day.title'}
      content={'buy.vignete.instruction.content'}
      subheader={'buy.vignet.instruction.subtitle'}
      messages={[
        'buy.vinget.instruction.one.day.item.1',
        'buy.vinget.instruction.one.day.item.2',
        'buy.vinget.instruction.one.day.item.3',
        'buy.vinget.instruction.one.day.item.4',
      ]}
      // description={{
      //   title: 'activate.instructions.desc.title',
      //   bullets: [],
      // }}
      iconURL={require('assets/10_minutes.svg')}
      iconStyle={{ height: 120, width: 120 }}
      onStart={() => props.goToPersonalInfo()}
    />
  );
};

export default InitializeExtension;
