import { connect } from 'react-redux';

import ExtendCustomerInfo from './components';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';

const mapDispatchToProps = dispatch => {
  return {
    getCustomer: customer_id => {
      dispatch(Creators.extendGetCustomerRequest(customer_id));
    },
    updateCustomer: customer_id => {
      dispatch(Creators.extendUpdateCustomerRequest(customer_id));
    },
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
  };
};

const mapStateToProps = state => {
  return {
    customer_id: state.extendVignet.data
      ? state.extendVignet.data.customer_id
      : '',
    customer: state.extendVignetData.customer,
    owner: state.buyVignet.owner,
    isFetched: state.extendVignetData.isFetched,
    isUpdated: state.extendVignetData.isUpdated,
    isLoading: state.extendVignetData.isLoading,
    isUpdating: state.extendVignetData.isUpdating,
    updateError: state.extendVignetData.updateError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendCustomerInfo);
