import React from 'react';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Alert, Row, Col } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import './styles.scss';
import { history } from 'util/helpers/history';
import { DELAY_SECONDS } from 'constants/index';

class AnnualPaymentInstruction extends React.Component {
  state = {
    visible: true,
  };

  getErrorMsg = pathname => {
    if (pathname.substring(41) === 'display') {
      return false;
    } else if (pathname.substring(41) === 'cancel') {
      return 'extend.step.payment.direct.cancel.message';
    } else if (pathname.substring(41) === 'error') {
      return 'extend.step.payment.direct.error.message';
    } else if (pathname.substring(41) === 'reject') {
      return 'extend.step.payment.direct.reject.message';
    }
  };
  render() {
    setTimeout(() => {
      this.setState({ visible: false });
    }, DELAY_SECONDS);
    return (
      <StepCard
        onPrevious={() => history.goBack()}
        onNext={() => this.props.checkMandate()}
        current={4}
        steps={7}
        id="buy.vignete.instruction.title"
        className="buy-payment-instruction"
        isNextLoading={this.props.isCheckingMandate}
      >
        <Row>
          <div>
            <p className="title">
              <FormattedMessage id="buy.vignet.payment.instruction.title" />
            </p>
          </div>
          <Col span={24} xs={24} lg={24} md={24} sm={24}>
            <div className="payment-type-wrapper">
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.description" />
              </p>
            </div>
            <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="buy.vignet.payment.instruction.title" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.date.content" />
              </p>
              {/* <p className="payment-info">
                <FormattedHTMLMessage id="buy.vignet.payment.instruction.date.content.more" />
              </p> */}
            </div>
            {/* <div className="payment-type-wrapper">
              <p className="payment-title">
                <FormattedMessage id="buy.vignet.payment.instruction.issue" />
              </p>
              <p className="payment-info">
                <FormattedMessage id="buy.vignet.payment.instruction.issue.content" />
              </p>
            </div> */}
            <div className="payment-type-wrapper">
              {this.getErrorMsg(history.location.pathname) &&
              this.state.visible ? (
                <Alert
                  type="error"
                  message={
                    <FormattedMessage
                      id={this.getErrorMsg(history.location.pathname)}
                    />
                  }
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

export default AnnualPaymentInstruction;
