import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import Title from 'components/Title';
import './styles.scss';
import StepCard from 'components/StepCard';

class BuyVignetChoice extends Component {
  render() {
    return (
      <StepCard className="buy-vignette-choice">
        <Title id="choose.header" />
        <Row type="flex" justify="center" gutter={24}>
          <Col xs={24} sm={24} lg={10} className="img-center-column">
            <img
              alt="renew vignette"
              className="main-icons"
              src={require('assets/updated_annual.svg')}
              onClick={() => this.props.goToBuyVignetteSteps()}
            />
            <p>
              <FormattedMessage id="BHG.vignet" />
            </p>
          </Col>
          <Col lg={1} xs={0} className="img-border-divider"></Col>
          <Col sm={24} xs={20} lg={10} className="img-center-column">
            <img
              alt="cancel vignette"
              className="main-icons"
              src={require('assets/1_day.svg')}
              onClick={() => this.props.goToOneDayInstruction()}
            />
            <p>
              <FormattedMessage id="mooring.vignet" />
            </p>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

BuyVignetChoice.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

export default BuyVignetChoice;
