import React from 'react';
import { connect } from 'react-redux';
import { Creators } from 'services/redux/buyVignette/actions';
import Success from 'components/Success';

const AnnualSuccess = props => {
  props.confirm();

  const documents = JSON.parse(sessionStorage.getItem('tarifChoices'));
  let id = 'buy.vignet.success.no.documents.message';
  if (documents && documents.documents && documents.documents.length) {
    id = 'buy.vignet.successful.message';
  }
  return (
    <Success
      variable={sessionStorage.getItem('request_number')}
      id={props.location.state ? props.location.state.id : id}
      titleId="buy.vignete.instruction.title"
      disabled
    />
  );
};

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return {
    confirm: () => {
      dispatch(Creators.buyVignetConfirmationRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnualSuccess);
