import React from 'react';
import { Steps } from 'antd';
import PropTypes from 'prop-types';

const { Step } = Steps;

export default class Stepper extends React.Component {
  state = {
    current: 0,
  };

  static propTypes = {
    numberOfSteps: PropTypes.number.isRequired,
  };

  onChange = current => {
    this.setState({ current });
  };

  renderSteps = () => {
    const { numberOfSteps } = this.props;
    let steps = [];
    for (let i = 1; i <= numberOfSteps; i++) {
      steps.push(<Step key={i} />);
    }

    return steps;
  };

  render() {
    return (
      <Steps
        current={this.props.currentStep}
        //onChange={this.onChange}
      >
        {this.renderSteps()}
      </Steps>
    );
  }
}
