import React from 'react';

import BuyVignetSuccessful from 'containers/BuyVignetteSteps/Steps/Success/components';

const OneDaySucess = props => {
  return (
    <BuyVignetSuccessful
      titleId="buy.vignet.instruction.one.day.title"
      id="buy.one.day.vignet.successful.message"
    />
  );
};

export default OneDaySucess;
