import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import Title from 'components/Title';
import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';

class ExtendStep3 extends Component {
  render() {
    return (
      <StepCard
        onPrevious={() =>
          this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.RATE_STEP)
        }
        className="extend-vignette-choice"
      >
        <Title id="choose.header" />
        <Row type="flex" justify="center" gutter={24}>
          <Col xs={24} sm={24} lg={10} className="img-center-column">
            <img
              alt="renew vignette"
              className="main-icons"
              src={require('assets/extension-icon.png')}
              onClick={() =>
                this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.PERSONAL_INFO_STEP)
              }
            />
            <p>
              <FormattedMessage id="extend.rate.renew.button" />
            </p>
          </Col>
          <Col lg={1} xs={0} className="img-border-divider"></Col>
          <Col sm={24} xs={20} lg={10} className="img-center-column">
            <img
              alt="cancel vignette"
              className="main-icons"
              src={require('assets/cancel.svg')}
              onClick={() =>
                this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.SIGNOUT_ROUTE)
              }
            />
            <p>
              <FormattedMessage id="extend.rate.cancel.button" />
            </p>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

ExtendStep3.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

export default ExtendStep3;
