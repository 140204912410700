import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import PaymentChoice from './components';

const mapStateToProps = state => {
  return {
    totalPayment: state.extendVignet.vignetCost,
    installment: state.extendVignetData.installment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    getInstallmentData: () => {
      dispatch(Creators.extendGetPaymentInstallmentRequest());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoice);
