import React, { Component } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

class LayoutPage extends Component {
  render() {
    const { children, goToHome } = this.props;
    return (
      <React.Fragment>
        <Header goToHome={goToHome} {...this.props} />
        <div className="container">{children}</div>
      </React.Fragment>
    );
  }
}

export default LayoutPage;
