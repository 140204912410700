import { connect } from 'react-redux';

import SignOut from './components';
import { Creators } from '../../../../services/redux/extendCancelVignette/actions';
import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';

const mapStateToProps = state => {
  return {
    isLoading: state.extendCancelVignette.isLoading,
    isCancelled: state.extendCancelVignette.isCancelled,
    error: state.extendCancelVignette.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    cancelVignette: email => dispatch(Creators.cancelVignetRequest(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
