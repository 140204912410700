import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  // vignette activation related state values
  vignet_number: '',
  pin: '',
  isCheckingPin: false,
  isCheckPinCodeError: false,
  checkPinCodeErrorMessage: undefined,
  checkPinCodeResult: undefined,
  // vignette activation end

  // reset pin code related state values
  isResetingPin: false,
  isResetPinCodeError: false,
  resetPinCodeErrorMessage: undefined,
  resetPinCodeResult: undefined,
  // reset pin code end

  // vessel types related state values
  isGettingVesselDetails: false,
  isGettingVesselDetailsError: false,
  getVesselDetailsErrorMessage: undefined,
  getVesselDetailsResult: undefined,
  // vessel types end

  // vessel types related state values
  isGettingVesselTypes: false,
  isGettingVesselTypesError: false,
  getVesselTypesErrorMessage: undefined,
  getVesselTypesResult: undefined,
  // vessel types end

  // update vessel data related state values
  isUpdatingVessel: false,
  isUpdatingVesselError: false,
  updateVesselErrorMessage: undefined,
  updateVesselResult: undefined,
  // update vessel end

  // upload vessel pictures data related state values
  isUploadingVesselPictures: false,
  isUploadingVesselPicturesError: false,
  uploadVesselPicturesErrorMessage: undefined,
  uploadVesselPicturesResult: undefined,
  // upload vessel pictures end
};

export const checkPinCode = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingPin: true,
    isCheckPinCodeError: false,
    vignet_number: action.vignet_number,
    pin: action.pin,
  };
};

export const checkPinCodeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingPin: false,
    isCheckPinCodeError: false,
    checkPinCodeResult: action.responseData,
  };
};

export const checkPinCodeFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isCheckingPin: false,
    isCheckPinCodeError: true,
    checkPinCodeErrorMessage: action.error,
  };
};

export const resetPinCode = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isResetingPin: true,
    isResetPinCodeError: false,
    vignet_number: action.vignet_number,
  };
};

export const resetPinCodeSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isResetingPin: false,
    isResetPinCodeError: false,
    resetPinCodeResult: action.responseData,
  };
};

export const resetPinCodeFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isResetingPin: false,
    isResetPinCodeError: true,
    resetPinCodeErrorMessage: action.error,
  };
};

export const getVesselTypes = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselTypes: true,
    isGettingVesselTypesError: false,
  };
};

export const getVesselTypesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselTypes: false,
    isGettingVesselTypesError: false,
    getVesselTypesResult: action.responseData,
  };
};

export const getVesselTypesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselTypes: false,
    isGettingVesselTypesError: true,
    getVesselTypesErrorMessage: action.error,
  };
};

export const vesselDetails = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselDetails: true,
    isGettingVesselDetailsError: false,
  };
};

export const vesselDetailsSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselDetails: false,
    isGettingVesselDetailsError: false,
    getVesselDetailsResult: action.responseData,
  };
};

export const vesselDetailsFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isGettingVesselDetails: false,
    isGettingVesselDetailsError: true,
    getVesselDetailsErrorMessage: action.error,
  };
};

export const updateVessel = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVessel: true,
    isUpdatingVesselError: false,
  };
};

export const updateVesselSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVessel: false,
    isUpdatingVesselError: false,
    updateVesselResult: action.responseData,
  };
};

export const updateVesselFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUpdatingVessel: false,
    isUpdatingVesselError: true,
    updateVesselErrorMessage: action.error,
  };
};

export const uploadVesselPictures = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploadingVesselPictures: true,
    isUploadingVesselPicturesError: false,
  };
};

export const uploadVesselPicturesSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploadingVesselPictures: false,
    isUploadingVesselPicturesError: false,
    uploadVesselPicturesResult: action.responseData,
  };
};

export const uploadVesselPicturesFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isUploadingVesselPictures: false,
    isUploadingVesselPicturesError: true,
    uploadVesselPicturesErrorMessage: action.error,
  };
};

export const onCompleteClear = () => {
  return {
    ...INITIAL_STATE,
  };
};

export const HANDLERS = {
  [Types.CHECK_PIN_CODE_REQUEST]: checkPinCode,
  [Types.CHECK_PIN_CODE_SUCCESS]: checkPinCodeSuccess,
  [Types.CHECK_PIN_CODE_FAILURE]: checkPinCodeFailure,

  [Types.RESET_PIN_CODE_REQUEST]: resetPinCode,
  [Types.RESET_PIN_CODE_SUCCESS]: resetPinCodeSuccess,
  [Types.RESET_PIN_CODE_FAILURE]: resetPinCodeFailure,

  [Types.GET_VESSEL_TYPES_REQUEST]: getVesselTypes,
  [Types.GET_VESSEL_TYPES_SUCCESS]: getVesselTypesSuccess,
  [Types.GET_VESSEL_TYPES_FAILURE]: getVesselTypesFailure,

  [Types.GET_VESSEL_DETAILS_REQUEST]: vesselDetails,
  [Types.GET_VESSEL_DETAILS_SUCCESS]: vesselDetailsSuccess,
  [Types.GET_VESSEL_DETAILS_FAILURE]: vesselDetailsFailure,

  [Types.UPDATE_VESSEL_REQUEST]: updateVessel,
  [Types.UPDATE_VESSEL_SUCCESS]: updateVesselSuccess,
  [Types.UPDATE_VESSEL_FAILURE]: updateVesselFailure,

  [Types.UPLOAD_VESSEL_PICTURES_REQUEST]: uploadVesselPictures,
  [Types.UPLOAD_VESSEL_PICTURES_SUCCESS]: uploadVesselPicturesSuccess,
  [Types.UPLOAD_VESSEL_PICTURES_FAILURE]: uploadVesselPicturesFailure,

  [Types.ON_COMPLETE_CLEAR]: onCompleteClear,
};

export default createReducer(INITIAL_STATE, HANDLERS);
