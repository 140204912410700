import React from 'react';
import { Form, Row, Col, Select } from 'antd';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import { FormattedMessage } from 'react-intl';

import './styles.scss';

const { Option } = Select;

class MandateBankLists extends React.Component {
  state = {
    bank: null,
  };
  componentDidMount() {
    if (this.props.getBankLists) {
      this.props.getBankLists();
      return;
    } else {
      this.props.getBanks();
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        sessionStorage.setItem('bank', values.bank);
        this.props.postMandate(values.bank);
      }
    });
  };

  renderMoreDetails = () => {
    return (
      <p>
        <FormattedMessage id="extend.bank.not.listed" />
        <br />
        <FormattedMessage id="extend.bank.authorization" />
      </p>
    );
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <StepCard
        isLoading={this.props.isFetchingBanks}
        isNextLoading={this.props.isFetchingMandate}
        onPrevious={() =>
          this.props.onPrevious
            ? this.props.onPrevious()
            : this.props.updateRoute(
                EXTEND_VIGNETTE_ROUTE.PAYMENT_INSTRUCTION_STEP,
              )
        }
        onNext={this.handleSubmit}
        className="extend-bank-lists"
        moreDetails={this.renderMoreDetails()}
        current={this.props.current}
        steps={this.props.steps}
        id={this.props.id}
      >
        <Row className="card-wrapper align-center">
          <Col span={24} xs={24} lg={4} md={24} sm={24}>
            <img src={require('assets/mandate.svg')} alt="mandate" />
          </Col>
          <Col span={24} xs={24} lg={17} md={24} sm={24}>
            <div className="bank-info">
              <span className="bank-title">
                <FormattedMessage id="extend.step.payment.bank.mandate" />
              </span>
              <Form.Item>
                {getFieldDecorator('bank', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(
                  <Select
                    style={{ width: '80%' }}
                    placeholder={
                      <FormattedMessage id="extend.step.payment.select.bank" />
                    }
                    onChange={this.onChange}
                  >
                    {this.props.banks && this.props.banks.length
                      ? this.props.banks.map(data => {
                          return (
                            <Option value={data.DebtorBankId} key={data.id}>
                              {data.DebtorBankName}
                            </Option>
                          );
                        })
                      : null}
                  </Select>,
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

const MandateBankForm = Form.create({ name: 'MandateBankLists' })(
  MandateBankLists,
);

export default MandateBankForm;
