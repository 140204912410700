import React from 'react';
import { Row, Col, Checkbox, Modal, Form, Alert } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import './styles.scss';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';

let timer;
class PartPaymentInstruction extends React.Component {
  state = {
    showModal: false,
    visible: true,
  };

  handleCancel = () => {
    this.setState({ showModal: false });
  };

  handleCheckBox = e => {
    this.setState({ checked: e.target.checked });
  };

  checkCheckBox = (rule, value, callback) => {
    if (!value) {
      callback(<FormattedMessage id="help.confirm.this" />);
    } else {
      callback();
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.FULL_PAYMENT_SUCCESSFUL);
        this.props.createInstallments();
      }
    });
  };
  renderModal = () => {
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={this.handleCancel}
        footer={null}
        width={720}
      >
        <div className="modal-wrapper">
          <p className="modal-title">
            <FormattedMessage id="extend.step.payment.terms.condition" />
          </p>
          <p className="modal-instruction">
            <FormattedHTMLMessage id={this.state.message} />
          </p>
        </div>
      </Modal>
    );
  };

  renderServerErrorMsg = () => {
    const { errorMsg } = this.props;
    if (errorMsg) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={errorMsg} type="error" />
          </div>
        );
      }
    }
  };
  render() {
    const { installment } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          id="extend.step.personal.title"
          current={5}
          steps={7}
          onPrevious={() =>
            this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.PAYMENT_CHOICE)
          }
          onNext={this.handleSubmit}
          className="part-payment-agreement"
          isNextLoading={this.props.isLoading}
        >
          {this.renderModal()}
          <Row>
            <div>
              <p className="title">
                <FormattedMessage id="extend.step.payment.installment.title" />
              </p>
            </div>
            <Col span={24} xs={24} lg={24} md={24} sm={24}>
              <p>
                <FormattedMessage id="extend.step.payment.installment.info" />
              </p>
              <div className="payment-type-wrapper">
                <p>
                  <FormattedMessage id="extend.step.payment.installment.info.two" />
                </p>
                <Row>
                  {installment &&
                  installment.installments &&
                  installment.installments.length ? (
                    <Col sm={12} lg={6} xs={24} md={12}>
                      <table border={1} className="installment-table">
                        <tr>
                          <th className="payment-installment-header">
                            <FormattedMessage id="extend.installment.period" />
                          </th>
                          <th className="payment-installment-header">
                            <FormattedMessage id="extend.installment.amount" />
                          </th>
                          <th className="payment-installment-header">
                            <FormattedMessage id="extend.installmennt.date" />
                          </th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            &#8364;&nbsp;
                            {formatAmountToNL(installment.installments[0])}
                          </td>
                          <td>
                            <FormattedMessage id="extend.installment.48hours" />
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            &#8364;&nbsp;
                            {formatAmountToNL(installment.installments[1])}
                          </td>
                          <td>
                            <FormattedMessage id="extend.installment.april" />
                          </td>
                        </tr>
                      </table>
                    </Col>
                  ) : null}
                </Row>
                <p className="mt-20">
                  <FormattedMessage id="extend.installment.table.info" />
                </p>
              </div>
              <div className="payment-type-wrapper">
                <Form.Item>
                  {getFieldDecorator('checkbox', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.checkCheckBox }],
                  })(
                    <Checkbox>
                      <span>
                        <FormattedMessage id="extend.step.payment.info.agreement" />
                      </span>
                    </Checkbox>,
                  )}
                  {/* <a
                    onClick={() =>
                      this.setState({
                        showModal: true,
                        message: 'extend.step.terms.and.condition.message',
                      })
                    }
                  >
                    <FormattedMessage id="extend.step.terms.and.condition" />
                  </a>
                  ,&nbsp; */}
                  <a
                    href="https://decentrale.regelgeving.overheid.nl/cvdr/xhtmloutput/Actueel/Amsterdam/CVDR635093.html"
                    target="_blank"
                  >
                    <FormattedMessage id="extend.step.port.rules" />
                    &nbsp;
                  </a>
                  en{' '}
                  <a
                    href="https://decentrale.regelgeving.overheid.nl/cvdr/xhtmloutput/Historie/Amsterdam/73996/CVDR73996_9.html"
                    target="_blank"
                  >
                    VOB
                  </a>
                </Form.Item>
              </div>
            </Col>
          </Row>
          {this.renderServerErrorMsg()}
        </StepCard>
      </Form>
    );
  }
}
const PartPaymentInstructionForm = Form.create({
  name: 'PartPaymentInstruction',
})(PartPaymentInstruction);
export default PartPaymentInstructionForm;
