import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import styles from './Title.module.scss';

const Title = props => {
  return (
    <h1 className={`${styles.title} ${props.className}`}>
      <FormattedMessage id={props.id} />
    </h1>
  );
};

Title.defaultProps = {
  className: '',
};

Title.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Title;
