import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = {
  isLoading: false,
  error: false,
  data: null,
  isFetching: false,
  vignetCost: null,
  vignetError: null,
};

export const extendVerificationRequest = (state = INITIAL_STATE, action) => {
  return { ...state, isLoading: true, error: false };
};

export const extendVerificationSuccess = (state = INITIAL_STATE, action) => {
  sessionStorage.setItem('access_token', action.responseData.access_token);
  return {
    ...state,
    isLoading: false,
    error: false,
    data: action.responseData,
  };
};

export const extendVerificationFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isLoading: false,
    error: action.error,
  };
};

//
export const extendGetVignetCostRequest = (state = INITIAL_STATE, action) => {
  return { ...state, isFetching: true, vignetError: false };
};

export const extendGetVignetCostSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    isFetched: true,
    vignetError: false,
    vignetCost: action.responseData,
  };
};

export const extendGetVignetCostFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isFetching: false,
    vignetError: action.error,
  };
};

export const HANDLERS = {
  [Types.EXTEND_VERIFICATION_REQUEST]: extendVerificationRequest,
  [Types.EXTEND_VERIFICATION_SUCCESS]: extendVerificationSuccess,
  [Types.EXTEND_VERIFICATION_FAILURE]: extendVerificationFailure,

  [Types.EXTEND_GET_VIGNET_COST_REQUEST]: extendGetVignetCostRequest,
  [Types.EXTEND_GET_VIGNET_COST_SUCCESS]: extendGetVignetCostSuccess,
  [Types.EXTEND_GET_VIGNET_COST_FAILURE]: extendGetVignetCostFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
