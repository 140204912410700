import React from 'react';
import { Form, Input, Alert, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { DELAY_SECONDS } from 'constants/index';

let timer;
class ForgotPassword extends React.Component {
  state = {
    visible: true,
  };
  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };
  render() {
    return (
      <div className="modal-wrapper">
        <p>
          <FormattedMessage id="buy.vignet.forgot.password.title" />
        </p>
        <label>
          <FormattedMessage id="email" />*
        </label>
        <Input type="email" onChange={value => this.props.onChange(value)} />
        {this.renderServerErrorMsg()}
        <div style={{ textAlign: 'center', marginTop: '40px' }}>
          <Button
            type="primary"
            disabled={this.props.isLoading}
            onClick={this.props.forgotPasswordSubmit}
          >
            <FormattedMessage id="CONFIRM" />
          </Button>
        </div>
      </div>
    );
  }
}
const ForgotPasswordForm = Form.create({ name: 'annual_forgot_password_form' })(
  ForgotPassword,
);
export default ForgotPasswordForm;
