import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = { isloading: false, error: false, data: null };

export const cancelVignet = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: false };
};

export const cancelVignetSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    isCancelled: true,
    error: false,
    data: action.responseData,
  };
};

export const cancelVignetFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: action.error,
  };
};

export const HANDLERS = {
  [Types.CANCEL_VIGNET_REQUEST]: cancelVignet,
  [Types.CANCEL_VIGNET_SUCCESS]: cancelVignetSuccess,
  [Types.CANCEL_VIGNET_FAILURE]: cancelVignetFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
