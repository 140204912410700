import { connect } from 'react-redux';

import ExtendRate from './components';
import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';

const mapStateToProps = state => {
  return {
    data: state.extendVignet.vignetCost,
    error: state.extendVignetData.isUpdatingError,
    isUpdating: state.extendVignetData.isUpdating,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    updateObjectLocation: object_location => {
      dispatch(Creators.extendUpdateObjectLocationRequest(object_location));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendRate);
