import React from 'react';
import { history } from 'util/helpers/history';
import CustomerInfo from 'containers/ExtendVignetteSteps/Steps/CustomerInfo/components';

const PersonalInfoForm = props => {
  return (
    <CustomerInfo
      title="buy.vignete.instruction.title"
      getOwner={() => props.getOwner()}
      isGettingInfo={props.isGettingInfo}
      customer={props.customer}
      onPrevious={() => history.goBack()}
      updateOwner={data => props.updateCustomer(data)}
      isUpdating={props.isUpdatingOwner}
      updateError={props.updateError}
      current={1}
      steps={7}
    />
  );
};

export default PersonalInfoForm;
