import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ChooseAction from './components';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  const { isloading, error, user } = state.auth;
  return {
    isloading,
    error,
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToActivateVignette: () => {
      history.push('/activate/vignette');
    },
    goToExtendVignette: () => {
      history.push('/extend/vignette');
    },
    goToBuyVignette: () => {
      history.push('/buy/vignette/annual');
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseAction);
