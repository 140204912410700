import { connect } from 'react-redux';

import { Creators as StepCreators } from 'services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import Login from './components';

const mapStateToProps = state => {
  return {
    isLoginIn: state.buyVignet.isLoginIn,
    error: state.buyVignet.loginError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    logIn: data => {
      dispatch(Creators.buyVignetLoginRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
