import React from 'react';
import StepsLayout from '../../StepsLayout';
import StepsController from '../../StepsController';
import { Row, Col, Form } from 'antd';
import VehicleDataForm from './VehicleDataForm';
import { FormattedMessage } from 'react-intl';
import { Creators } from 'services/redux/vignette/actions';
import { connect } from 'react-redux';
import { StepCard } from 'components/StepCard';

class StepTwo extends React.Component {
  get vesselTypes() {
    return this.props.getVesselTypesResult.object_types || [];
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.props.getVesselTypes();
    this.props.getVesselDetails();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isUpdatingVessel &&
      !this.props.isUpdatingVessel &&
      !this.props.isUpdatingVesselError
    ) {
      setTimeout(this.props.nextStep, 0);
    }
  }

  onSubmit = e => {
    e.preventDefault();
    this.nextStep();
  };

  prevStep = () => {
    this.props.prevStep();
  };

  nextStep = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors && values.checked) {
        this.props.updateVessel(values);
      }
    });
  };

  render() {
    return (
      <StepCard
        id="activate.steps.header"
        steps={this.props.steps}
        current={this.props.current}
        onPrevious={this.prevStep}
        onNext={this.nextStep}
        xs={23}
        sm={22}
        md={14}
        lg={10}
        xl={16}
        xxl={13}
        className="step-two"
      >
        <Row>
          <Row type="flex" justify="center">
            <Col>
              <h2>
                <FormattedMessage id="activate.steps.two.header" />
              </h2>
              <p>
                <FormattedMessage id="activate.steps.two.subheader" />
              </p>
            </Col>
          </Row>
        </Row>

        <Row>
          <Row type="flex" justify="center">
            <Col xs={20}>
              <VehicleDataForm
                isLoading={
                  this.props.isGettingVesselTypes ||
                  this.props.isGettingVesselDetails ||
                  this.props.isUpdatingVessel
                }
                isError={
                  this.props.isGettingVesselTypesError ||
                  this.props.isGettingVesselDetailsError ||
                  this.props.isUpdatingVesselError
                }
                help={
                  this.props.getVesselTypesErrorMessage ||
                  this.props.getVesselDetailsErrorMessage ||
                  this.props.updateVesselErrorMessage
                }
                form={this.props.form}
                onSubmit={this.onSubmit}
                vesselTypes={this.vesselTypes}
              />
            </Col>
          </Row>
        </Row>
      </StepCard>
    );
  }
}

const mapStateToProps = state => {
  const {
    isGettingVesselTypes,
    isGettingVesselTypesError,
    getVesselTypesErrorMessage,
    getVesselTypesResult = {},

    isUpdatingVessel,
    isUpdatingVesselError,
    updateVesselErrorMessage,

    isGettingVesselDetails,
    getVesselDetailsResult = {},
  } = state.vignette;

  return {
    isGettingVesselTypes,
    isGettingVesselTypesError,
    getVesselTypesErrorMessage,
    getVesselTypesResult,

    isUpdatingVessel,
    isUpdatingVesselError,
    updateVesselErrorMessage,

    isGettingVesselDetails,
    getVesselDetailsResult,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVesselTypes: () => {
      dispatch(Creators.getVesselTypesRequest());
    },
    getVesselDetails: () => {
      dispatch(Creators.getVesselDetailsRequest());
    },
    updateVessel: ({ object_name, object_type }) => {
      dispatch(Creators.updateVesselRequest(object_name, object_type));
    },
  };
};

const mapPropsToFields = props => {
  return {
    object_name: Form.createFormField({
      value: props.getVesselDetailsResult.object_name || '',
    }),
    object_type: Form.createFormField({
      value: props.getVesselDetailsResult.object_type || '',
    }),
  };
};

const WrappedForm = Form.create({
  name: 'vehicle-data',
  mapPropsToFields,
})(StepTwo);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedForm);
