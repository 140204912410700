import { connect } from 'react-redux';

import ExtendVignetteSteps from './Steps';

const mapStateToProps = state => {
  return {
    route: state.extendVignetteSteps.route,
  };
};

export default connect(mapStateToProps, {})(ExtendVignetteSteps);
