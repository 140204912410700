import React from 'react';
import { Row, Form, Input, Button, Icon, Spin } from 'antd';
import { injectIntl } from 'react-intl';

const ForgotPinForm = props => {
  const {
    isLoading,
    wasSuccess,
    onSubmit,
    isResetPinCodeError,
    resetPinCodeErrorMessage,
    intl,
    form: { getFieldDecorator },
  } = props;

  if (isLoading) {
    return (
      <Row type="flex" justify="center">
        <Spin />
      </Row>
    );
  }

  if (wasSuccess) {
    return (
      <React.Fragment>
        <h1 className="forgot-pin-form success-header">
          <img src={require('assets/activate-success-icon.png')} />
        </h1>
        <p className="forgot-pin-form success-message">
          {intl.formatMessage({ id: 'vignette.activation.requestpin.success' })}
        </p>
      </React.Fragment>
    );
  }

  return (
    <Form onSubmit={onSubmit} className="forgot-pin-form" align="start">
      <p>
        {intl.formatMessage({ id: 'vignette.activation.requestpin.form.text' })}
      </p>

      <Form.Item label={intl.formatMessage({ id: 'vignette.number' })}>
        {getFieldDecorator('vignet_number', {
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'activate.errors.enter.number',
              }),
            },
          ],
        })(<Input type="text" />)}
      </Form.Item>

      {isResetPinCodeError && (
        <Form.Item
          help={resetPinCodeErrorMessage}
          validateStatus="error"
        ></Form.Item>
      )}

      <br />

      <Row type="flex" justify="center">
        <Button type="primary" htmlType="submit">
          {intl.formatMessage({
            id: 'NEXT',
          })}
          <Icon type="arrow-right" />
        </Button>
      </Row>
    </Form>
  );
};

const WrappedForgotPinForm = Form.create({
  name: 'forgot_pin',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
})(ForgotPinForm);

export default injectIntl(WrappedForgotPinForm);
