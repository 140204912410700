import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import Login from './components';

const mapStateToProps = state => {
  return {
    isLoginIn: state.buyVignet.isLoginIn,
    error: state.buyVignet.loginError,
    forgottingPassword: state.buyVignet.forgettingPassword,
    forgotPasswordError: state.buyVignet.forgetPasswordError,
    sentForgotPassword: state.buyVignet.sentForgotPassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logIn: data => {
      dispatch(Creators.buyVignetLoginRequest(data));
    },
    forgotPassword: data => {
      dispatch(Creators.buyVignetForgotPasswordRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
