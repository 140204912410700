import React from 'react';
import { history } from 'util/helpers/history';
import AccountForm from 'containers/ExtendVignetteSteps/Steps/Account/components';

const RegisterForm = props => {
  return (
    <AccountForm
      onPrevious={() => history.goBack()}
      onSubmit={values => props.createAccount(values)}
      isLoading={props.isCreatingAccount}
      error={props.accountError}
      current={1}
      steps={7}
      id="buy.vignete.instruction.title"
      instruction="buy.vignet.account.creation.instruction"
    />
  );
};

export default RegisterForm;
