import { createReducer } from 'reduxsauce';
import Types from './actionTypes';
import { normalizeData } from 'util/helpers/normalizer';

export const INITIAL_STATE = {
  isloading: false,
  error: false,
  customer: {},
  installment: null,
  banks: null,
};

export const extendGetCustomerRequest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: true,
    error: false,
    isUpdated: false,
    isUpdating: false,
  };
};

export const extendGetCustomerVignetSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isloading: false,
    isFetched: true,
    error: false,
    customer: action.responseData,
  };
};

export const extendGetCustomerVignetFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isloading: false,
    error: action.error,
  };
};

export const extendUpdateCustomerRequest = (state = INITIAL_STATE, action) => {
  return { ...state, isUpdating: true, error: false };
};

export const extendUpdateCustomerVignetSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isUpdating: false,
    isUpdated: true,
    error: false,
    data: action.responseData,
  };
};

export const extendUpdateCustomerVignetFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isUpdating: false,
    updateError: action.error,
  };
};

export const extendUpdateCredentialsRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return { ...state, isCreatingAccount: true, accountError: false };
};

export const extendUpdateCredentialsVignetSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCreatingAccount: false,
    isCreatedAccount: true,
    accountError: false,
    account: action.responseData,
  };
};

export const extendUpdateCredentialsVignetFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCreatingAccount: false,
    accountError: action.error,
  };
};

export const extendGetPaymentInstallmentRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isGettingInstallment: true,
    isGettingInstallmentError: false,
  };
};

export const extendGetPaymentInstallmentVignetSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isGettingInstallment: false,
    isFetchedInstallment: true,
    isGettingInstallmentError: false,
    installment: action.responseData,
  };
};

export const extendGetPaymentInstallmentVignetFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isGettingInstallment: false,
    isGettingInstallmentError: action.error,
  };
};

export const extendGetPaymentBankListsRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingBanks: true,
    isFetchingBanksError: false,
  };
};

export const extendGetPaymentBankListsSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingBanks: false,
    isFetchedBanks: true,
    isFetchingBanksError: false,
    banks: normalizeData(action.responseData),
  };
};

export const extendGetPaymentBankListsFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingBanks: false,
    isFetchingBanksError: action.error,
  };
};

export const extendGetPaymentMandateRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingMandate: true,
    isFetchingMandateError: false,
  };
};

export const extendGetPaymentMandateSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingMandate: false,
    isFetchedMandate: true,
    isFetchingBanksError: false,
    mandate: action.responseData,
  };
};

export const extendGetPaymentMandateFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingMandate: false,
    isFetchingMandateError: action.error,
  };
};

export const extendGetPaymentDirectRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingDirect: true,
    isFetchingDirectError: false,
  };
};

export const extendGetPaymentDirectSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingDirect: false,
    isFetchedDirect: true,
    isFetchingBanksError: false,
    direct: action.responseData,
  };
};

export const extendGetPaymentDirectFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isFetchingDirect: false,
    isFetchingDirectError: action.error,
  };
};

export const extendUpdateObjectLocationRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isUpdating: true,
    isUpdatingError: false,
  };
};

export const extendUpdateObjectLocationSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isUpdating: false,
    isUpdated: true,
    isUpdatingError: false,
  };
};

export const extendUpdateObjectLocationFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isUpdating: false,
    isUpdatingError: action.error,
  };
};

export const extendPaymentHasMandateRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCheckingMandate: true,
    isCheckingMandateError: false,
  };
};

export const extendPaymentHasMandateSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCheckingMandate: false,
    isCheckedMandate: true,
    isCheckingMandateError: false,
  };
};

export const extendPaymentHasMandateFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCheckingMandate: false,
    isCheckingMandateError: action.error,
  };
};

export const extendCreateInstallmentsRequest = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCreatingInstallments: true,
    isCreatingInstallmentsError: false,
  };
};

export const extendCreateInstallmentsSuccess = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCreatingInstallments: false,
    isCheckedMandate: true,
    isCreatingInstallmentsError: false,
  };
};

export const extendCreateInstallmentsFailure = (
  state = INITIAL_STATE,
  action,
) => {
  return {
    ...state,
    isCreatingInstallments: false,
    isCreatingInstallmentsError: action.error,
  };
};

export const HANDLERS = {
  [Types.EXTEND_GET_CUSTOMER_REQUEST]: extendGetCustomerRequest,
  [Types.EXTEND_GET_CUSTOMER_SUCCESS]: extendGetCustomerVignetSuccess,
  [Types.EXTEND_GET_CUSTOMER_FAILURE]: extendGetCustomerVignetFailure,

  [Types.EXTEND_UPDATE_CUSTOMER_REQUEST]: extendUpdateCustomerRequest,
  [Types.EXTEND_UPDATE_CUSTOMER_SUCCESS]: extendUpdateCustomerVignetSuccess,
  [Types.EXTEND_UPDATE_CUSTOMER_FAILURE]: extendUpdateCustomerVignetFailure,

  [Types.EXTEND_UPDATE_CREDENTIALS_REQUEST]: extendUpdateCredentialsRequest,
  [Types.EXTEND_UPDATE_CREDENTIALS_SUCCESS]: extendUpdateCredentialsVignetSuccess,
  [Types.EXTEND_UPDATE_CREDENTIALS_FAILURE]: extendUpdateCredentialsVignetFailure,

  [Types.EXTEND_GET_PAYMENT_INSTALLMENT_REQUEST]: extendGetPaymentInstallmentRequest,
  [Types.EXTEND_GET_PAYMENT_INSTALLMENT_SUCCESS]: extendGetPaymentInstallmentVignetSuccess,
  [Types.EXTEND_GET_PAYMENT_INSTALLMENT_FAILURE]: extendGetPaymentInstallmentVignetFailure,

  [Types.EXTEND_GET_PAYMENT_BANK_LISTS_REQUEST]: extendGetPaymentBankListsRequest,
  [Types.EXTEND_GET_PAYMENT_BANK_LISTS_SUCCESS]: extendGetPaymentBankListsSuccess,
  [Types.EXTEND_GET_PAYMENT_BANK_LISTS_FAILURE]: extendGetPaymentBankListsFailure,

  [Types.EXTEND_GET_PAYMENT_MANDATE_REQUEST]: extendGetPaymentMandateRequest,
  [Types.EXTEND_GET_PAYMENT_MANDATE_SUCCESS]: extendGetPaymentMandateSuccess,
  [Types.EXTEND_GET_PAYMENT_MANDATE_FAILURE]: extendGetPaymentMandateFailure,

  [Types.EXTEND_GET_PAYMENT_DIRECT_REQUEST]: extendGetPaymentDirectRequest,
  [Types.EXTEND_GET_PAYMENT_DIRECT_SUCCESS]: extendGetPaymentDirectSuccess,
  [Types.EXTEND_GET_PAYMENT_DIRECT_FAILURE]: extendGetPaymentDirectFailure,

  [Types.EXTEND_UPDATE_OBJECT_LOCATION_REQUEST]: extendUpdateObjectLocationRequest,
  [Types.EXTEND_UPDATE_OBJECT_LOCATION_SUCCESS]: extendUpdateObjectLocationSuccess,
  [Types.EXTEND_UPDATE_OBJECT_LOCATION_FAILURE]: extendUpdateObjectLocationFailure,

  [Types.EXTEND_PAYMENT_HAS_MANDATE_REQUEST]: extendPaymentHasMandateRequest,
  [Types.EXTEND_PAYMENT_HAS_MANDATE_SUCCESS]: extendPaymentHasMandateSuccess,
  [Types.EXTEND_PAYMENT_HAS_MANDATE_FAILURE]: extendPaymentHasMandateFailure,

  [Types.EXTEND_CREATE_INSTALLMENTS_REQUEST]: extendCreateInstallmentsRequest,
  [Types.EXTEND_CREATE_INSTALLMENTS_SUCCESS]: extendCreateInstallmentsSuccess,
  [Types.EXTEND_CREATE_INSTALLMENTS_FAILURE]: extendCreateInstallmentsFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
