import React from 'react';
import {
  Row,
  Col,
  Input,
  Radio,
  Form,
  Alert,
  Spin,
  Tooltip,
  Icon,
  Modal,
  Checkbox,
  Button,
} from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import './styles.scss';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import { formatAmountToNL } from 'util/helpers/normalizer';

let timer;

class TarifChoices extends React.Component {
  state = {
    milieu: null,
    marina: null,
    night: null,
    removeOpacity: false,
    visible: true,
    timeout: null,
    showModal: false,
    checkbox: false,
  };

  onSelectMilieu = e => {
    this.setState({
      milieu: e.target.value,
    });

    this.props.clearData();
  };

  onSelectMarina = e => {
    this.setState({
      marina: e.target.value,
      removeOpacity: true,
    });
    this.props.clearData();
  };

  onSelectNight = e => {
    this.setState({
      night: e.target.value,
    });
    this.setState({
      removeOpacity: true,
    });
    this.props.clearData();
  };

  validNumber = (rule, value, callback) => {
    const { form } = this.props;
    const regex = /^[0-9.,]*$/;
    if (regex.test(value)) {
      callback();
    } else {
      callback(<FormattedMessage id="not_valid_field" />);
    }
    this.props.clearData();
  };

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        const data = {
          milieu: values.milieu,
          marina: values.marina,
          length: values.length.replace(',', '.'),
          width: values.width.replace(',', '.'),
        };
        this.props.getTarifChoices(data);
      }
    });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  renderDocumentInfo = () => {
    const { data } = this.props;
    if (data && data.documents) {
      return (
        <div>
          <p>
            <FormattedHTMLMessage id="buy.vignet.document.info.title" />
          </p>
          <p>
            <FormattedHTMLMessage id="buy.vignet.document.info" />
          </p>
          <ul>
            {data.documents.map(doc => (
              <li style={{ listStyleType: 'square' }}>
                {doc !== 'Ontheffing' ? (
                  <React.Fragment>
                    {doc}{' '}
                    <Tooltip
                      title={
                        <FormattedHTMLMessage id="buy.vignet.document.tooltip" />
                      }
                    >
                      <Icon type="info-circle" className="info-icon-wrapper" />
                    </Tooltip>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {doc}{' '}
                    {this.props.data &&
                    this.props.data.vignet_type === 'Regulier' ? null : (
                      <a
                        href="https://www.amsterdam.nl/veelgevraagd/?caseid=%7B4669F01E-2601-42E9-90AD-101BAB243A88%7D"
                        target="_blank"
                        // onClick={() => this.setState({ showModal: true })}
                      >
                        (<FormattedMessage id="buy.vignet.click.here" />)
                      </a>
                    )}
                  </React.Fragment>
                )}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  renderModal = () => {
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        footer={null}
        width={720}
      >
        <div className="modal-wrapper">
          <p className="modal-title">
            <FormattedMessage id="buy.vignet.tarif.modal.title" />
          </p>
          <p className="modal-instruction">
            {this.props.data && this.props.data.vignet_type === 'Abonnement' ? (
              <FormattedHTMLMessage id="buy.vignet.tarif.modal.description" />
            ) : (
              <FormattedHTMLMessage id="buy.vignet.tarif.modal.milieu.description" />
            )}
          </p>
          <div style={{ marginTop: '20px' }}>
            <Checkbox
              onChange={() => this.setState({ checkbox: !this.state.checkbox })}
            >
              <FormattedMessage id="buy.vignet.tarif.modal.checkbox" />
            </Checkbox>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              type="primary"
              disabled={!this.state.checkbox}
              onClick={() => {
                this.setState({ showModal: false });
                this.props.updateRoute(
                  BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE,
                );
              }}
            >
              <FormattedMessage id="CONFIRM" />
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { data, isAuthed } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} initialValues={{}}>
        <StepCard
          onPrevious={() =>
            this.props.updateRoute(BUY_VIGNNETTE_ROUTE.INSTRUCTION_ROUTE)
          }
          onNext={() =>
            // this.setState({ showModal: true })
            this.props.data &&
            ['Abonnement', 'Milieu'].includes(this.props.data.vignet_type)
              ? this.setState({ showModal: true })
              : this.props.updateRoute(BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE)
          }
          current={0}
          steps={7}
          id="buy.vignete.instruction.title"
          className="buy-vignet-tarif-choices"
          isNextDisabled={!this.props.data}
          isAuthed={isAuthed}
        >
          <Row className="wrapper">
            <div>
              <p className="title">
                <FormattedMessage id="buy.vignet.tarif.choices.title" />
              </p>
            </div>

            <Col span={24} xs={24} lg={12} md={24} sm={24}>
              <p className="main-question-title">
                <FormattedMessage id="buy.vignet.tarif.choices.instruction.title" />
              </p>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.choices.instruction.description" />
                </p>
                {getFieldDecorator('milieu', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(
                  <Radio.Group
                    onChange={this.onSelectMilieu}
                    value={this.state.milieu}
                  >
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={1}>
                          <FormattedMessage id="buy.vignet.tarif.boat.option.electrical" />
                        </Radio>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={0}>
                          <FormattedMessage id="buy.vignet.tarif.boat.option.other" />
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>,
                )}
              </Form.Item>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.boat.size" />
                </p>
                <Row>
                  <Col
                    span={24}
                    xs={24}
                    lg={8}
                    md={24}
                    sm={24}
                    className="form-wrapper"
                  >
                    <label>
                      <FormattedMessage id="extend.rate.length" />
                    </label>
                    <Form.Item>
                      {getFieldDecorator('length', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.validNumber,
                          },
                        ],
                      })(<Input suffix="m" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24} xs={24} lg={1} md={24} sm={24}></Col>
                  <Col span={24} xs={24} lg={8} md={24} sm={24}>
                    <label>
                      <FormattedMessage id="extend.rate.width" />
                    </label>
                    <Form.Item>
                      {getFieldDecorator('width', {
                        rules: [
                          {
                            required: true,
                            message: <FormattedMessage id="required.error" />,
                          },
                          {
                            validator: this.validNumber,
                          },
                        ],
                      })(<Input suffix="m" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item className="form-wrapper">
                <p className="tarif-question-label">
                  <FormattedMessage id="buy.vignet.tarif.place.label" />
                </p>
                {getFieldDecorator('marina', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(
                  <Radio.Group
                    onChange={this.onSelectMarina}
                    value={this.state.marina}
                  >
                    <Row>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={1}>
                          <FormattedMessage id="yes" />
                        </Radio>
                      </Col>
                      <Col
                        span={24}
                        xs={24}
                        lg={24}
                        md={24}
                        sm={24}
                        className="radio-button-item"
                      >
                        <Radio value={0}>
                          <FormattedMessage id="no" />
                        </Radio>
                      </Col>
                    </Row>
                  </Radio.Group>,
                )}
              </Form.Item>
            </Col>
            <Col span={24} xs={24} lg={5} md={24} sm={24}>
              {this.props.isRequesting ? (
                <Spin size="large" />
              ) : (
                <div
                  id="comment_bubble"
                  style={this.state.removeOpacity ? { opacity: 1 } : null}
                  onClick={this.handleSubmit}
                >
                  <div className="calculate-wrapper">
                    <img src={require('assets/cog.png')} />
                    <p>
                      <FormattedMessage id="calculate" />
                    </p>
                  </div>
                </div>
              )}
            </Col>
            <Col span={24} xs={24} lg={6} md={24} sm={24}>
              <label>
                <FormattedMessage id="buy.vignet.tarif.boat.surface" />
              </label>
              <Form.Item>
                <Input
                  disabled
                  value={data ? formatAmountToNL(data.surface) : null}
                  {...(data
                    ? {
                        suffix: (
                          <span>
                            m<sup>2</sup>
                          </span>
                        ),
                      }
                    : {})}
                />
              </Form.Item>
              <label>
                <FormattedMessage id="vignet_type" />
              </label>
              <Form.Item>
                <Input disabled value={data ? data.vignet_type : null} />
              </Form.Item>
              <label>
                <FormattedMessage id="request.overview.amount.toPay" />
              </label>
              <Form.Item>
                <Input
                  disabled
                  {...(data ? { prefix: <span>&#8364;</span> } : {})}
                  value={data ? formatAmountToNL(data.vignet_cost) : null}
                />
              </Form.Item>
              {this.renderDocumentInfo()}
              {this.renderServerErrorMsg()}
              {this.renderModal()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}
const TarifChoicesForm = Form.create({ name: 'TarifChoicesForm' })(
  TarifChoices,
);
export default TarifChoicesForm;
