import './index.scss';
import React from 'react';
import StepsLayout from '../../StepsLayout';
import StepsController from '../../StepsController';
import { Row, Col, Button, Form, Upload, Icon, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import PicturesForm from './PicturesForm';
import { Creators } from 'services/redux/vignette/actions';
import { connect } from 'react-redux';
import { toBase64 } from './helpers';
import ConfirmationModal from './ConfirmationModal';
import StepCard from 'components/StepCard';

const distant_photo_url = require('assets/boat1-copyright.jpeg');
const detail_photo_url = require('assets/boat2-copyright.jpeg');

class StepFour extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      distant_photo_url,
      detail_photo_url,
      distant_photo: null,
      detail_photo: null,
      isDistantError: true,
      isDetailError: true,
      isConfirmationModalOpen: false,
    };

    // one file inputs ref for each image field, to open os dailogs on web and mobile
    this.distant = React.createRef();
    this.detail = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    // validate image fields on update
    if (
      prevState.distant_photo !== this.state.distant_photo ||
      prevState.detail_photo !== this.state.detail_photo
    ) {
      this.validateAll();
    }

    // checks if the form were submitted and has as successful API call
    // to go next (step)
    if (
      prevProps.isUploadingVesselPictures &&
      !this.props.isUploadingVesselPictures &&
      !this.props.isUploadingVesselPicturesError
    ) {
      setTimeout(this.props.nextStep, 0);
    }
  }

  // will return if either fields are valid or not (only checking if files exists)
  // and trigger/show UI error messages for user in-case if there's an error
  validateAll = () => {
    let isDistantError = true;
    let isDetailError = true;

    if (this.state.distant_photo instanceof File) {
      isDistantError = false;
    }
    if (this.state.detail_photo instanceof File) {
      isDetailError = false;
    }

    this.setState({
      isDistantError,
      isDetailError,
    });

    return !(isDistantError || isDetailError);
  };

  prevStep = () => {
    this.props.prevStep();
  };

  // next step is constrained by having the form valid, then only submits the form
  nextStep = () => {
    this.closeModal();

    const isValid = this.validateAll();
    if (isValid) {
      this.props
        .toBase64(this.state.distant_photo, this.state.detail_photo)
        .then(([distant_photo, detail_photo]) => {
          this.props.uploadVesselPictures(distant_photo, detail_photo);
        })
        .catch(e => {
          message.error(e.message);
        });
    }
  };

  onSubmit = () => {
    this.openModal();
  };

  updateFields = (key, value) => {
    const preview = URL.createObjectURL(value);
    this.setState({
      // thumbnail to view for user
      [`${key}_url`]: preview,
      // the actual file object or image
      [key]: value,
    });
  };

  // for the Dropzone (dropping images on the thumbnail)
  handleFilesDrop = (name, files) => {
    const file = files[0];
    if (/^image\/(png|jpg|jpeg)$/.test(file.type)) {
      this.updateFields(name, file);
    }
  };

  // wrap updateFields to select first file only and add to state
  handleFileInput = e => {
    if (e.target.files && e.target.files.length) {
      this.updateFields(e.target.name, e.target.files[0]);
    }
  };

  // handles thumbnail clicks, and is callable by handleUploadClick
  openUploadDialog = name => {
    if (name === 'distant_photo') {
      this.distant.current.click();
    } else if (name === 'detail_photo') {
      this.detail.current.click();
    }
  };

  // to handle upload Button click and based on name attr, calls/open the right dailog
  handleUploadClick = e => {
    if (e.target.name) {
      this.openUploadDialog(e.target.name);
    }
  };

  closeModal = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  openModal = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  render() {
    const refs = {
      distant: this.distant,
      detail: this.detail,
    };
    const isError = this.state.isDistantError || this.state.isDetailError;
    const isNextDisabled = isError;

    return (
      <StepCard
        id="activate.steps.header"
        steps={this.props.steps}
        current={this.props.current}
        onPrevious={this.prevStep}
        onNext={this.onSubmit}
        isNextDisabled={isNextDisabled}
        isNextLoading={this.props.isUploadingVesselPictures}
        xs={23}
        sm={22}
        md={14}
        lg={10}
        xl={16}
        xxl={13}
        className="step-four"
      >
        <ConfirmationModal
          visible={this.state.isConfirmationModalOpen}
          onConfirm={this.nextStep}
          onCancel={this.closeModal}
        />

        <Row>
          <Row type="flex" justify="center">
            <Col>
              <h2>
                <FormattedMessage id="activate.steps.four.header" />
              </h2>
              <p>
                <FormattedMessage id="activate.steps.four.subheader" />
              </p>
            </Col>
          </Row>
        </Row>

        <PicturesForm
          ref={refs}
          isLoading={this.props.isUploadingVesselPictures}
          isError={this.props.isUploadingVesselPicturesError}
          help={this.props.uploadVesselPicturesErrorMessage}
          distant_photo_url={this.state.distant_photo_url}
          detail_photo_url={this.state.detail_photo_url}
          isDistantError={this.state.isDistantError}
          isDetailError={this.state.isDetailError}
          handleFileInput={this.handleFileInput}
          handleFilesDrop={this.handleFilesDrop}
          openUploadDialog={this.openUploadDialog}
          handleUploadClick={this.handleUploadClick}
        />
      </StepCard>
    );
  }
}

const mapStateToProps = state => {
  const {
    isUploadingVesselPictures,
    isUploadingVesselPicturesError,
    uploadVesselPicturesErrorMessage,
    uploadVesselPicturesResult = {},
  } = state.vignette;

  return {
    isUploadingVesselPictures,
    isUploadingVesselPicturesError,
    uploadVesselPicturesErrorMessage,
    uploadVesselPicturesResult,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    async toBase64(distant, detail) {
      let distant_photo = await toBase64(distant);
      let detail_photo = await toBase64(detail);
      return [distant_photo, detail_photo];
    },
    uploadVesselPictures(distant_photo, detail_photo) {
      dispatch(
        Creators.uploadVesselPicturesRequest(distant_photo, detail_photo),
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StepFour);
