import React from 'react';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Input, Row, Col, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';

let timer;
class Login extends React.Component {
  state = {
    visible: true,
  };
  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.logIn(values);
      }
    });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() =>
            this.props.updateRoute(BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE)
          }
          onNext={this.handleSubmit}
          current={1}
          steps={7}
          id="buy.vignete.instruction.title"
          className="buy-vignet-tarif-choices"
          isNextLoading={this.props.isLoginIn}
        >
          <Title id="login" />
          <Row>
            <Col
              span={24}
              xs={24}
              lg={8}
              push={8}
              md={24}
              sm={24}
              className="form-wrapper"
            >
              <label>
                <FormattedMessage id="email" />*
              </label>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      type: 'email',
                      message: <FormattedMessage id="email.regex.error" />,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <label>
                <FormattedMessage id="passwordLabel" />*
              </label>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
              <a
                onClick={() => console.log()}
                style={{ textDecoration: 'underline' }}
              >
                <FormattedMessage id="forgotPassword" />
              </a>
              {this.renderServerErrorMsg()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const LoginForm = Form.create({ name: 'LoginForm' })(Login);

export default LoginForm;
