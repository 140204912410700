import { createReducer } from 'reduxsauce';
import Types from './actionTypes';

export const INITIAL_STATE = { isloading: false, error: false, user: null };

export const login = (state = INITIAL_STATE, action) => {
  return { ...state, isloading: true, error: false };
};

export const loginSuccess = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: false,
    user: action.responseData,
  };
};

export const loginFailure = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    isloading: false,
    error: true,
    error_message: action.error,
  };
};

export const HANDLERS = {
  [Types.LOGIN_REQUEST]: login,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGIN_FAILURE]: loginFailure,
};

export default createReducer(INITIAL_STATE, HANDLERS);
