import React from 'react';
import DirectMandateForm from 'containers/ExtendVignetteSteps/Steps/DirectPaymentBank/components';
import { history } from 'util/helpers/history';

const AnnualDirectPayment = props => {
  return (
    <DirectMandateForm
      id="buy.vignete.instruction.title"
      getBankLists={() => props.getBankLists({ list_type: 'ideal' })}
      banks={props.banks}
      isFetchingBanks={props.isFetchingBanks}
      postDirect={data => props.createDirect(data)}
      isNextLoading={props.isMakingAnnualPayment}
      onPrevious={() => history.goBack()}
    />
  );
};

export default AnnualDirectPayment;
