import { connect } from 'react-redux';

import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import { Creators } from '../../../../services/redux/extendVignette/actions';
import DirectMandateForm from './components';

const mapStateToProps = state => {
  return {
    banks: state.extendVignetData.banks,
    isFetchingBanks: state.extendVignetData.isFetchingBanks,
    error: state.extendVignetData.isFetchingDirectError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    getBanks: () => {
      dispatch(Creators.extendGetPaymentBankListsRequest('ideal'));
    },
    postDirect: issuer => {
      dispatch(Creators.extendGetPaymentDirectRequest(issuer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectMandateForm);
