import React from 'react';
import { Row, Col, Input, Form, Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import QrReader from 'react-qr-reader';

import './styles.scss';
import { EXTEND_VIGNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';

let timer;

class ExtendVerification extends React.Component {
  static propTypes = {
    goBack: PropTypes.func.isRequired,
    verifyVignet: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    visible: true,
    timeout: null,
  };

  handleScan = data => {
    if (data) {
      let splitted_data = data.split('/');
      this.setState({ showQR: false, vignet_number: splitted_data[3] });
    }
  };
  handleError = err => {
    this.setState({ showQR: false });
    alert('cannot scan');
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ visible: true });
        const { vignet_number, bsn } = values;
        this.props.verifyVignet(vignet_number, bsn);
      }
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && this.props.isFetched) {
      this.props.updateRoute(EXTEND_VIGNETTE_ROUTE.RATE_STEP);
    }
  }

  renderQRCode = () => {
    return (
      <QrReader
        delay={300}
        onError={this.handleError}
        onScan={this.handleScan}
        style={{ width: '100%' }}
      />
    );
  };

  renderImages = () => {
    return (
      <div className="img-wrapper">
        <Row>
          <Col span={12} xs={24} lg={12} md={12} sm={24}>
            <img
              alt="verification"
              className="extend-verification-img"
              src={require('../../../../../assets/vignet_example.jpg')}
              onClick={() => this.setState({ showQR: true })}
            />
          </Col>
          <Col span={12} xs={24} lg={12} md={12} sm={24}>
            <img
              alt="qr-code"
              className="extend-verification-img"
              src={require('../../../../../assets/verification2.png')}
              onClick={() => this.setState({ showQR: true })}
            />
          </Col>
        </Row>
      </div>
    );
  };

  renderForms = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        <div className="input-wrapper mobile-input">
          <label>
            <FormattedMessage id="extend.verification.number" />*
          </label>
          <Form.Item>
            {getFieldDecorator('vignet_number', {
              initialValue: this.state.vignet_number,
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id="required.error" />,
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>
        <div className="input-wrapper">
          <label>
            <FormattedMessage id="extend.verification.bsn" />*
          </label>
          <Form.Item>
            {getFieldDecorator('bsn', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage id="required.error" />,
                },
              ],
            })(<Input />)}
          </Form.Item>
        </div>
        {this.renderServerErrorMsg()}
      </React.Fragment>
    );
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, 5000);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <StepCard
          onPrevious={() => this.props.goBack()}
          isNextLoading={this.props.isLoading}
          current={0}
          steps={7}
          id="form.title"
          className="verification-step"
          onNext={this.handleSubmit}
          contactDetails="extend.step.verification.contact"
        >
          <Row gutter={24} className="wrapper">
            <div>
              <p className="title">
                <FormattedMessage id="extend.verification" />
              </p>
            </div>
            {this.state.showQR && this.renderQRCode()}
            <Col span={24} xs={24} lg={12} md={24} sm={24}>
              <div className="info-wrapper">
                <p>
                  <FormattedMessage id="extend.verification.instruction" />
                </p>
                {this.renderImages()}
                {/* <p>
                  <FormattedMessage id="extend.step.verification.contact" />
                  <br />
                  Tel: 0800123456
                  <br />
                  contact@digitaalvignet.nl
                </p> */}
              </div>
            </Col>
            <Col span={12} xs={24} lg={2} md={4} sm={24}></Col>
            <Col span={12} xs={24} lg={10} md={24} sm={24}>
              {this.renderForms()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const ExtendVerificationForm = Form.create({ name: 'extendVerificationForm' })(
  ExtendVerification,
);
export default ExtendVerificationForm;
