import { axiosInstance } from './axiosDefaults';

export const apiRequest = (method, url, data = {}, token = null) => {
  const response = axiosInstance(token)({
    method,
    url,
    data,
    // withCredentials: false,
    token,
  });
  return response;
};
