import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import Mandate from './components';

const mapStateToProps = state => {
  return {
    banks: state.buyVignet.banks,
    isFetchingBanks: state.buyVignet.isGettingBanks,
    isCreatingMandate: state.buyVignet.isCreatingMandate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getBankLists: data => {
      dispatch(Creators.buyVignetGetBankListsRequest(data));
    },
    createMandate: issuer => {
      dispatch(Creators.buyVignetCreateMandateRequest(issuer));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mandate);
