import { connect } from 'react-redux';

import { Creators } from '../../../../services/redux/extendVerification/actions';
import { Creators as StepsCreators } from '../../../../services/redux/extendVignetteSteps/actions';
import ExtendVerification from './components';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  return {
    isLoading: state.extendVignet.isLoading,
    isFetching: state.extendVignet.isFetching,
    isFetched: state.extendVignet.isFetched,
    error: state.extendVignet.error,
    route: state.extendVignetteSteps.route,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goBack: () => history.push('/extend/vignette'),
    verifyVignet: (vignet_number, bsn) => {
      dispatch(Creators.extendVerificationRequest(vignet_number, bsn));
    },
    updateRoute: route => {
      dispatch(StepsCreators.updateExtendVignetteRoute(route));
    },
    getVignetCost: vignet_id => {
      dispatch(Creators.extendGetVignetCostRequest(vignet_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtendVerification);
