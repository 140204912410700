import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Title from 'components/Title';
import './styles.scss';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import StepCard from 'components/StepCard';

class ChooseAccount extends Component {
  render() {
    return (
      <StepCard
        onPrevious={() =>
          this.props.updateRoute(BUY_VIGNNETTE_ROUTE.TARIF_CHOICES_ROUTE)
        }
        id="buy.vignete.instruction.title"
        className="extend-vignette-choice"
      >
        <Title id="buy.vinget.instruction.item.2" />
        <Row type="flex" justify="center" gutter={24}>
          <Col xs={24} sm={24} lg={10} className="img-center-column">
            <p style={{ marginTop: '30%' }}>
              <FormattedHTMLMessage id="buy.vignet.account.description" />
            </p>
          </Col>
          <Col lg={1} xs={0} className="img-border-divider"></Col>
          <Col sm={24} xs={20} lg={10}>
            <div>
              <img
                alt="renew vignette"
                className="main-icons"
                src={require('assets/no_account.svg')}
                onClick={() =>
                  this.props.updateRoute(BUY_VIGNNETTE_ROUTE.LOGIN_ROUTE)
                }
              />
              <FormattedMessage id="buy.vignet.have.account" />
            </div>
            <div>
              <img
                alt="cancel vignette"
                className="main-icons"
                src={require('assets/has_account.svg')}
                onClick={() =>
                  this.props.updateRoute(
                    BUY_VIGNNETTE_ROUTE.ACCOUNT_CREATION_ROUTE,
                  )
                }
              />
              <FormattedMessage id="buy.vignet.no.account" />
            </div>
          </Col>
        </Row>
      </StepCard>
    );
  }
}

ChooseAccount.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

export default ChooseAccount;
