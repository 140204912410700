import React from 'react';
import StepCard from 'components/StepCard';
import Title from 'components/Title';
import { Form, Input, Row, Col, Alert, Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet';

import { history } from 'util/helpers/history';
import { DELAY_SECONDS, CURRENT_URL } from 'constants/index';
import ForgotPasswordForm from './forgotPassword';

let timer;
class Login extends React.Component {
  state = {
    visible: true,
    showModal: false,
    forgot_email: '',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sentForgotPassword) {
      this.setState({ showModal: false });
    }
  }

  handleSubmit = e => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.logIn(values);
      }
    });
  };
  forgotPasswordSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.forgot_email,
      base_url: `${CURRENT_URL}/buy/vignette/annual`,
    };
    this.props.forgotPassword(data);
  };
  forgotPasswordOnChange = e => {
    this.setState({ forgot_email: e.target.value });
  };

  renderServerErrorMsg = () => {
    const { error } = this.props;
    if (error) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setState({ visible: false });
      }, DELAY_SECONDS);
      if (this.state.visible) {
        return (
          <div className="error-msg">
            <Alert description={error} type="error" />
          </div>
        );
      }
    }
  };

  renderForgotPassword = () => {
    return (
      <Modal
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        footer={null}
        width={520}
      >
        <ForgotPasswordForm
          isLoading={this.props.forgottingPassword}
          error={this.props.forgotPasswordError}
          forgotPassword={this.props.forgotPassword}
          forgotPasswordSubmit={this.forgotPasswordSubmit}
          onChange={this.forgotPasswordOnChange}
        />
      </Modal>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    let formData = sessionStorage.getItem('annual_login_form');
    if (!formData) {
      formData = {};
    } else {
      formData = JSON.parse(formData);
    }
    return (
      <Form onSubmit={this.handleSubmit}>
        <Helmet>
          <meta
            http-equiv="clear-site-data"
            content='"cache","storage","cookies"'
          />
        </Helmet>
        <StepCard
          onPrevious={() => history.goBack()}
          onNext={this.handleSubmit}
          current={1}
          steps={7}
          id="buy.vignete.instruction.title"
          className="buy-vignet-tarif-choices"
          isNextLoading={this.props.isLoginIn}
        >
          <Title id="login" />
          <Row type="flex" justify="space-around" align="middle">
            <Col
              span={24}
              xs={24}
              lg={8}
              md={24}
              sm={24}
              className="form-wrapper"
            >
              <label>
                <FormattedMessage id="email" />*
              </label>
              <Form.Item>
                {getFieldDecorator('email', {
                  initialValue: formData.email,
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                    {
                      type: 'email',
                      message: <FormattedMessage id="email.regex.error" />,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
              <label>
                <FormattedMessage id="passwordLabel" />*
              </label>
              <Form.Item>
                {getFieldDecorator('password', {
                  initialValue: formData.password,
                  rules: [
                    {
                      required: true,
                      message: <FormattedMessage id="required.error" />,
                    },
                  ],
                })(<Input type="password" />)}
              </Form.Item>
              <a
                onClick={() => this.setState({ showModal: true })}
                style={{ textDecoration: 'underline' }}
              >
                <FormattedMessage id="forgotPassword" />
              </a>
              {this.renderServerErrorMsg()}
              {this.renderForgotPassword()}
            </Col>
          </Row>
        </StepCard>
      </Form>
    );
  }
}

const LoginForm = Form.create({ name: 'LoginForm' })(Login);

export default LoginForm;
