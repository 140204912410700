import { createTypes } from 'reduxsauce';

export default createTypes(
  `
  BUY_VIGNET_GET_TARIF_CHOICES_REQUEST
  BUY_VIGNET_GET_TARIF_CHOICES_SUCCESS
  BUY_VIGNET_GET_TARIF_CHOICES_FAILURE

  BUY_VIGNET_CLEAR_TARIF_CHOICES

  BUY_VIGNET_LOGIN_REQUEST
  BUY_VIGNET_LOGIN_SUCCESS
  BUY_VIGNET_LOGIN_FAILURE

  BUY_VIGNET_CREATE_ACCOUNT_REQUEST
  BUY_VIGNET_CREATE_ACCOUNT_SUCCESS
  BUY_VIGNET_CREATE_ACCOUNT_FAILURE

  BUY_VIGNET_GET_OWNER_REQUEST
  BUY_VIGNET_GET_OWNER_SUCCESS
  BUY_VIGNET_GET_OWNER_FAILURE

  BUY_VIGNET_UPDATE_OWNER_REQUEST
  BUY_VIGNET_UPDATE_OWNER_SUCCESS
  BUY_VIGNET_UPDATE_OWNER_FAILURE

  BUY_VIGNET_GET_OBJECT_TYPES_REQUEST
  BUY_VIGNET_GET_OBJECT_TYPES_SUCCESS
  BUY_VIGNET_GET_OBJECT_TYPES_FAILURE

  BUY_VIGNET_GET_DRIVE_TYPES_REQUEST
  BUY_VIGNET_GET_DRIVE_TYPES_SUCCESS
  BUY_VIGNET_GET_DRIVE_TYPES_FAILURE

  BUY_VIGNET_CREATE_DAY_REQUEST
  BUY_VIGNET_CREATE_DAY_SUCCESS
  BUY_VIGNET_CREATE_DAY_FAILURE

  BUY_VIGNET_GET_BANK_LISTS_REQUEST
  BUY_VIGNET_GET_BANK_LISTS_SUCCESS
  BUY_VIGNET_GET_BANK_LISTS_FAILURE

  BUY_VIGNET_MAKE_DAY_PAYMENT_REQUEST
  BUY_VIGNET_MAKE_DAY_PAYMENT_SUCCESS
  BUY_VIGNET_MAKE_DAY_PAYMENT_FAILURE

  BUY_VIGNET_GET_OVERVIEW_REQUEST
  BUY_VIGNET_GET_OVERVIEW_SUCCESS
  BUY_VIGNET_GET_OVERVIEW_FAILURE

  BUY_VIGNET_UPLOAD_DOCUMENTS_REQUEST
  BUY_VIGNET_UPLOAD_DOCUMENTS_SUCCESS
  BUY_VIGNET_UPLOAD_DOCUMENTS_FAILURE

  BUY_VIGNET_HAS_MANDATE_REQUEST
  BUY_VIGNET_HAS_MANDATE_SUCCESS
  BUY_VIGNET_HAS_MANDATE_FAILURE

  BUY_VIGNET_CREATE_MANDATE_REQUEST
  BUY_VIGNET_CREATE_MANDATE_SUCCESS
  BUY_VIGNET_CREATE_MANDATE_FAILURE

  BUY_VIGNET_GET_INSTALLMENTS_REQUEST
  BUY_VIGNET_GET_INSTALLMENTS_SUCCESS
  BUY_VIGNET_GET_INSTALLMENTS_FAILURE

  BUY_VIGNET_GET_VIGNET_COST_REQUEST
  BUY_VIGNET_GET_VIGNET_COST_SUCCESS
  BUY_VIGNET_GET_VIGNET_COST_FAILURE

  BUY_VIGNET_CREATE_DIRECT_REQUEST
  BUY_VIGNET_CREATE_DIRECT_SUCCESS
  BUY_VIGNET_CREATE_DIRECT_FAILURE

  BUY_VIGNET_CREATE_INSTALLMENT_REQUEST
  BUY_VIGNET_CREATE_INSTALLMENT_SUCCESS
  BUY_VIGNET_CREATE_INSTALLMENT_FAILURE

  BUY_VIGNET_CHECK_DAY_REQUEST
  BUY_VIGNET_CHECK_DAY_SUCCESS
  BUY_VIGNET_CHECK_DAY_FAILURE

  BUY_VIGNET_CONFIRMATION_REQUEST
  BUY_VIGNET_CONFIRMATION_SUCCESS
  BUY_VIGNET_CONFIRMATION_FAILURE

  BUY_VIGNET_FORGOT_PASSWORD_REQUEST
  BUY_VIGNET_FORGOT_PASSWORD_SUCCESS
  BUY_VIGNET_FORGOT_PASSWORD_FAILURE

  BUY_VIGNET_RESET_PASSWORD_REQUEST
  BUY_VIGNET_RESET_PASSWORD_SUCCESS
  BUY_VIGNET_RESET_PASSWORD_FAILURE

`,
  {},
);
