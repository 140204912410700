import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import AnnualResetPassword from './components';

const mapStateToProps = state => {
  return {
    isResetting: state.buyVignet.resettingPassword,
    error: state.buyVignet.resetPasswordError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: data => {
      dispatch(Creators.buyVignetResetPasswordRequest(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnnualResetPassword);
