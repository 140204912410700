import { createTypes } from 'reduxsauce';

export default createTypes(
  `
    CHECK_PIN_CODE_REQUEST
    CHECK_PIN_CODE_SUCCESS
    CHECK_PIN_CODE_FAILURE

    RESET_PIN_CODE_REQUEST
    RESET_PIN_CODE_SUCCESS
    RESET_PIN_CODE_FAILURE

    GET_VESSEL_TYPES_REQUEST
    GET_VESSEL_TYPES_SUCCESS
    GET_VESSEL_TYPES_FAILURE

    GET_VESSEL_DETAILS_REQUEST
    GET_VESSEL_DETAILS_SUCCESS
    GET_VESSEL_DETAILS_FAILURE

    UPDATE_VESSEL_REQUEST
    UPDATE_VESSEL_SUCCESS
    UPDATE_VESSEL_FAILURE

    UPLOAD_VESSEL_PICTURES_REQUEST
    UPLOAD_VESSEL_PICTURES_SUCCESS
    UPLOAD_VESSEL_PICTURES_FAILURE

    ON_COMPLETE_CLEAR
  `,
  {},
);
