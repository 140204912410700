import React from 'react';
import Instruction from 'components/Instruction';
import { history } from 'util/helpers/history';

const AnnualInstructions = props => {
  return (
    <Instruction
      header={'buy.vignete.instruction.title'}
      content={'buy.vignete.instruction.content'}
      subheader={'buy.vignet.instruction.subtitle'}
      messages={[
        'buy.vinget.instruction.item.1',
        'buy.vinget.instruction.item.2',
        'buy.vinget.instruction.item.3',
        'buy.vinget.instruction.item.4',
        'buy.vinget.instruction.item.5',
        'buy.vinget.instruction.item.6',
        'buy.vinget.instruction.item.7',
      ]}
      iconURL={require('assets/timer_15.svg')}
      iconStyle={{ height: 120, width: 120 }}
      onStart={() => history.push('/buy/vignette/annual/tarif')}
      contact="annual.instruction.description"
    />
  );
};

export default AnnualInstructions;
