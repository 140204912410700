import React from 'react';

import { connect } from 'react-redux';

import { Creators as StepCreators } from 'services/redux/extendVignetteSteps/actions';
import { Creators } from 'services/redux/buyVignette/actions';
import BuyVignetteSteps from './Steps';

const mapStateToProps = state => {
  return {
    route: state.extendVignetteSteps.buy_vignete_route,
    isCreatingAccount: state.buyVignet.isCreatingAccount,
    isUpdatingOwner: state.buyVignet.isUpdatingOwner,
    updateError: state.buyVignet.updateError,
    customer: state.buyVignet.owner,
    error: state.buyVignet.error,
    banks: state.buyVignet.banks,
    overview: state.buyVignet.overview,
    accountError: state.buyVignet.accountError,
    isFetchingBanks: state.buyVignet.isGettingBanks,
    isMakingDayPayment: state.buyVignet.isMakingDayPayment,
    makingDayPaymentError: state.buyVignet.makingDayPaymentError,
    isCreatingMandate: state.buyVignet.isCreatingMandate,
    refNumber: state.buyVignet.uploadedDocuments.reference_number,
    isMakingAnnualPayment: state.buyVignet.creatingDirect,
    checkingDay: state.buyVignet.checkingDay,
    checkDayError: state.buyVignet.checkDayError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRoute: route => {
      dispatch(StepCreators.updateBuyVignetteRoute(route));
    },
    createAccount: data => {
      dispatch(Creators.buyVignetCreateAccountRequest(data));
    },
    getOwner: () => {
      dispatch(Creators.buyVignetGetOwnerRequest());
    },
    updateCustomer: data => {
      dispatch(Creators.buyVignetUpdateOwnerRequest(data));
    },
    getBankLists: data => {
      dispatch(Creators.buyVignetGetBankListsRequest(data));
    },
    makeDayPayment: issuer => {
      dispatch(Creators.buyVignetMakeDayPaymentRequest(issuer));
    },
    createMandate: issuer => {
      dispatch(Creators.buyVignetCreateMandateRequest(issuer));
    },
    createDirect: issuer => {
      dispatch(Creators.buyVignetCreateDirectRequest(issuer));
    },
    checkDay: data => {
      dispatch(Creators.buyVignetCheckDayRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyVignetteSteps);
