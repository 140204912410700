import './index.scss';
import React, { Component } from 'react';
// import InputField from '../../../components/InputField';
// import { required } from '../../../utils/validations';
import PropTypes from 'prop-types';
import { Row, Col, Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import Title from 'components/Title';

let winNav = window.navigator;
let isOpera = typeof window.opr !== 'undefined';

let msie = winNav.userAgent.indexOf('MSIE ');
let isInternetExplorer =
  msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

class ChooseAction extends Component {
  renderComponent = () => {
    return (
      <React.Fragment>
        <Row type="flex" justify="center" gutter={24} className="row-wrapper">
          <Col xs={20} lg={7} md={24} sm={24} className="align-payment-center">
            <img
              alt="activate vignette"
              className="main-icons"
              src={require('assets/activate-icon.png')}
              onClick={this.props.goToActivateVignette}
            />
            <p>
              <FormattedMessage id="choose.activate" />
            </p>
          </Col>
          {/* <Col lg={1} xs={0} className="img-border-divider"></Col>
          <Col xs={20} lg={7} md={24} sm={24} className="align-payment-center">
            <img
              alt="extend vignette"
              className="main-icons"
              src={require('assets/extension-icon.png')}
              onClick={this.props.goToExtendVignette}
            />
            <p>
              <FormattedMessage id="choose.extend" />
            </p>
          </Col> */}
          <Col lg={1} xs={0} className="img-border-divider"></Col>
          <Col xs={20} lg={7} md={24} sm={24} className="align-payment-center">
            <img
              alt="extend vignette"
              className="main-icons"
              src={require('assets/buy_vignette.png')}
              onClick={this.props.goToBuyVignette}
            />
            <p>
              <FormattedMessage id="buy.vignet" />
            </p>
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  renderBrowserNotSupported = () => {
    return (
      <React.Fragment>
        <p className="browser-not-supported">
          <FormattedMessage id="browser.not.supported" />
        </p>
      </React.Fragment>
    );
  };
  render() {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: '90vh' }}
        className="choose-action"
      >
        <Col sm={4} md={14} lg={14}>
          <Card align="center">
            <Title id="choose.header" />
            {isInternetExplorer || isOpera
              ? this.renderBrowserNotSupported()
              : this.renderComponent()}
          </Card>
        </Col>
      </Row>
    );
  }
}

ChooseAction.propTypes = {
  isloading: PropTypes.bool,
  goToActivateVignette: PropTypes.func.isRequired,
  goToExtendVignette: PropTypes.func.isRequired,
};

// you can use formik or redux forms

export default ChooseAction;
