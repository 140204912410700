export default {
  vignette: 'Vignette',
  'vignette.number': 'Vignette number',
  'vignette.activationpin': 'Activation pin',
  lastname: 'Last name',
  insertion: 'Insertion',
  intial: 'Initial (s)',
  address: 'Address',
  'house.number': 'House number',
  addition: 'Addition',
  'postal.code': 'Postal Code',
  place: 'Place',
  'telephone.number': 'telephone number',
  mobile: 'Mobile',
  date: 'Date',

  START: 'START',
  BACK: 'BACK',
  NEXT: 'FURTHER',
  COMPLETE: 'COMPLETE',
  UPLOAD: 'UPLOAD',
  CONFIRM: 'CONFIRM',
  yes: 'yes',
  no: 'no',
  'type.vignet': 'Type Vignet',
  price: 'price',
  calculate: 'calculate',
  login: 'Log in',
  'SELECT.PHOTO': 'SELECT PHOTO',
  filled_in: 'ingevuld',
  fileRequired: 'You still need to upload a document',

  'help.confirm.this': 'You must confirm this',

  confirmation: 'Confirmation',
  email: 'Email',
  passwordLabel: 'Password',
  password: 'Create a password',
  forgotPassword: 'Forgot Password',
  confirmEmail: 'Confirm email address',
  confirmPassword: 'Confirm password',
  'password.strength.error':
    'Password must consist of: 8 characters, at least 2 digits and a capital letter.',
  'required.error': 'This field is required',
  passWordNotMatch: 'Password does not match',
  emailNotMatch: 'Email does not match',
  rate: 'Rate',
  'payment.method': 'Payment method',
  'personal.details': 'Personal details',
  'summary.application': 'Summary of your application:',
  name: 'Name',
  bsn: 'BSN',
  day_vignet: 'Day Vignette',
  vignet_type: 'Type of vignette',
  not_valid_field: 'field is not valid',
  more_than_50_chars: 'The name can contain up to 50 characters',
  'regular.boat': 'regular',
  'mileu.boat': 'environment',
  'abonnement.boat': 'subscription',
  'email.regex.error':
    'e-mail address must be in the format: janedoe@example.com',
  electrical: 'Electric',
  'buy.vignet.marina.place': 'Place in a marina or outside Amsterdam',
  'length.of.boat': 'Length of your boat',
  'width.of.boat': 'Width of your boat',

  'vessel.name': 'Name of vessel',
  'errors.enter.vessel.name': 'Please enter name of vessel!',
  'vessel.type': 'Vessel type',
  'errors.select.vessel.type': 'Please select type of vessel!',
  'vessel.data.confirmation':
    'Confirm that you have entered the information truthfully',

  'choose.header': 'Make a choice',
  'choose.activate': 'Activiate Vignette',
  'choose.extend': 'Extend Vignette',

  'activate.header': 'BHG Vignette activation',
  'activate.subheader':
    'Practical information <subtext>(Note! At once!)</subtext>',
  'activate.instructions.one':
    'Verification with a new vignette number and action pin',
  'activate.instructions.two': 'Verification of vessel details and rate',
  'activate.instructions.three': 'Verification of personal data',
  'activate.instructions.four': 'Upload photos from your vessel',
  'activate.instructions.five': 'E-mail confirmation',
  'activate.instructions.desc.title':
    'You must have the following information to hand:',
  'activate.instructions.bullets.one': 'Your new vignette',
  'activate.instructions.bullets.two': 'The activation pin',
  'activate.instructions.bullets.three':
    'Photos of your vessel incl. Vignette attached',

  'activate.steps.header': 'Activate BHG Vignette',
  'vignette.activation.lostpin': 'Lost activation pin? ',
  'vignette.activation.requestpin': 'Request a new one here.',
  'vignette.activation.requestpin.success':
    'The new activation pin has been sent to the email address associated with this vignette.',
  'vignette.activation.requestpin.form.text':
    'Enter your vignette number here. A message will be sent to the email address that is linked to this vignette.',
  'activate.steps.one.header': 'Verification',
  'activate.steps.one.subheader':
    'Enter your vignette number and activation pin here',
  'activate.steps.one.description':
    'Enter your new vignette number and activation pin here. The vignette number can be found on the <bold>new</bold> physical vignette. The activation pin has been sent to you via email.',

  'activate.steps.two.header': 'Vehicle data',
  'activate.steps.two.subheader':
    'Check your vessel details. If these are not correct, you can adjust them.',

  'activate.steps.four.header': 'Upload image',
  'activate.steps.four.subheader':
    'You must upload two photos of your boat here',

  'activate.steps.four.distant.title': 'Detail photo',
  'activate.steps.four.distant.description':
    'Take a photo of your vessel on which the vignette can be read.',

  'activate.steps.four.detail.title': 'Photo entire boat',
  'activate.steps.four.detail.description':
    'Take a photo that shows your entire vessel.',
  'activate.steps.four.confirm.message':
    'Pay attention! These photos will be checked. {break} {break} This confirms that the photos are a correct representation of your vessel.',

  'activate.steps.five.heading': 'Thank you!',
  'activate.steps.five.message':
    'Your vignette has been successfully activated and a confirmation email has been sent.',
  'activate.steps.five.submessage':
    'The municipality of Amsterdam wishes you a lot of sailing pleasure!',

  'activate.errors.enter.number': 'Please enter your vignette number here!',
  'activate.errors.enter.pin': 'Please input your Pin here!',
  'activate.errors.pick.picture': 'Please pick a picture!',

  'extend.start.first.instruction':
    'Verification by social security number and current vignette number',
  'extend.start.second.instruction': 'Check vessel details and rate',
  'extend.start.third.instruction': 'Choice: extend BHG or cancel vignette',
  'extend.start.fourth.instruction':
    'Check personal details and fill in the missing details',
  'extend.start.fifth.instruction': 'Create a BHG account',
  'extend.start.sixth.instruction': 'Choose a payment method',
  'extend.start.seventh.instruction': 'E-mail confirmation',

  'form.title': 'BHG Vignette',
  'extend.verification': 'Verification',
  'extend.verification.number': 'Current vignette number',
  'extend.verification.bsn': 'BSN number',
  'extend.step.verification.contact':
    'Unable to verify? Then contact the help desk.',
  'extend.step.verification.contact.number':
    'Help desk opening hours 8 a.m. - 4 p.m.',
  'extend.verification.instruction':
    'Enter your current vignette number and social security number. The vignette number can be found on your current vignette or on the renewal letter as shown in the figure below.',

  'extend.rate.instruction':
    'These are the BHG rates per vignette type. Your current data is shown on the right-hand side and the corresponding rate for 2020 is calculated.',
  'extend.rate.instruction.more':
    'Pay attention! In the next step you will be given the choice to extend or cancel this vignette.',
  'extend.rate.title': 'Rate 2020',
  'extend.rate.description':
    'If the information shown is incorrect, please contact the help desk.',
  'extend.rate.type': 'Vignette type',
  'extend.rate.length': 'Length',
  'extend.rate.width': 'Width',
  'extend.rate.surface': 'Surface',
  'extend.rate.cost': 'Total costs for 2020',
  'extend.rate.modal.instruction':
    'Pay attention! After receiving the new vignette, it must be activated.',
  'extend.rate.modal.more.instruction':
    'When activating the vignette you must upload a photo of your vessel.',
  'extend.rate.modal.confirm':
    'I hereby confirm that the information displayed is correct.',
  'extend,rate.modal.enivronment.confirmation':
    'Herewith I confirm that the vessel is propelled exclusively by muscle, wind or electric motor',
  'extend.rate.modal.subscription.confirmation':
    'I hereby confirm that the vessel is in a Marina during the year 2020',
  'extend.rate.renew.button': 'I want to renew my vignette',
  'extend.rate.cancel.button': 'I want to cancel my vignette',
  'extend.cancel.signout.title': 'Unsubscribe BHG Vignette',
  'extend.signout': 'Sign out',
  'extend.signout.message':
    'By canceling your vignette you will not pay any inland port dues in the coming year. This means that you can no longer sail, moor or take a berth with your vessel on the inland waters of the Municipality of Amsterdam. Other consequences:',
  'extend.signout.consequences':
    'Enter your email address here so that we can send you a cancellation confirmation.',
  'extend.signout.agree': 'Hereby I confirm the cancellation of my vignette',
  'extend.signout.terms.link':
    'General Terms and Conditions, Inland Harbor Dues Regulation and VOB',

  'instruction.extend': 'Extend/unsubscribe BHG Vignette',
  'instruction.extend.subheader':
    'Practical information <subtext>(Pay attention! You must go through this process in one go!)</subtext>',

  'extend.cancellation.message':
    'You have successfully unsubscribed your vignette and we have sent you a confirmation email.',

  'extend.step.personal.title': 'Extend BHG Vignette',
  'extend.step.personal.info': 'Personal information',
  'extend.step.personal.info.checkbox.message':
    'I confirm that I have entered the above information correctly',
  'extend.step.account': 'Create an account',
  'extend.step.account.information':
    'Here you will create your own BHG account. You will be able to use this account in the future to manage your data and information purposes.',
  'extend.step.payment.terms.title': 'Continuous authorization',
  'extend.step.payment.terms.one':
    'To pay your BHG rate for 2020 and the following years, you must issue a digital authorization. As a result, the BHG rate is debited from your bank account number through a continuous, automatic collection. In the following step you can choose between the BHG rate at once or pay in installments.',
  'extend.step.payment.terms.header.one.title': 'Pay at once',
  'extend.step.payment.terms.header.one.content':
    'If you pay in one go, you will pay the entire BHG rate for the coming year by means of an IDEAL payment. For the following years, the rate will be automatically debited from your account, if you state otherwise.',
  'extend.step.payment.terms.header.two.title': 'Pay in installments',
  'extend.step.payment.terms.header.two.content':
    'Automatic payment for this year applies to payment in installments. The full BHG rate is split into 3 installments, the first installment (50% of the rate) being immediately debited from your bank account within 48 hours. The following two installments will be debited from your bank account number on 1 March (25%) and 1 April (25%).',
  'extend.step.payment.choice.title': 'BHG 2020 rate',
  'extend.step.payment.choice.full': 'Pay at once',
  'extend.step.payment.choice.installment': 'Pay in installments',
  'extend.step.payment.choice.admin.cost': 'including administration costs',
  'extend.step.payment.full.instruction.one':
    'For the year 2020 you pay the rate directly through an IDEAL payment. This rate means that from 1 January 2020 to 31 December 2020 you are entitled to take a berth on the inland waterways of the municipality of Amsterdam.',
  'extend.step.payment.full.instruction.two':
    'The following years, the rate will be automatically debited from your account, if you state otherwise.',
  'extend.step.payment.info.agreement':
    'I hereby confirm that I agree with the',
  'extend.step.payment.terms':
    'General Terms and Conditions, Inland Harbor Dues Regulation and VOB',
  'extend.step.payment.terms.condition': 'Terms and Conditions',
  'extend.step.payment.successful':
    'Your vignette has been successfully extended and we have sent you a confirmation email.',
  'extend.step.payment.installment.title': 'Pay in installments',
  'extend.step.payment.installment.info':
    'Automatic payment for this year applies to payment in installments. The full BHG rate is split into 3 installments, the first installment (50% of the rate) being immediately debited from your bank account within 48 hours. The following two installments will be debited from your bank account number on 1 March (25%) and 1 April (25%). On top of the rate there will be a one-off administration fee, for which an amount of € 20 will be charged.',
  'extend.step.payment.installment.info.two':
    'You pay your BCR at four installments',
  'extend.step.payment.within.hour': 'within 48 hours',
  'extend.step.payment.march': 'March 1',
  'extend.step.payment.april': 'April 1st',

  'extend.step.payment.bank.mandate': 'Authorization - Choose your bank',
  'extend.step.payment.select.bank': 'Select a bank',
  'extend.step.payment.bank.ideal': 'iDEAL - Choose your bank',

  'extend.step.vignette.rate.hover.message': 'hover message',
  'extend.step.vignette.rate.second.hover.message': 'second hover message',
  'extend.step.vignette.rate.third.hover.message': 'third hover message',
  'extend.step.vignette.rate.fourth.hover.message': 'fourth hover message',

  'extend.step.rate.marina.name': 'Marina Name',
  'extend.step.terms.and.condition': 'Terms and Conditions',
  'extend.step.terms.and.condition.message': `<p><strong>GENERAL TERMS AND CONDITIONS OF PAYMENT WITHIN PLEASURE FUN</strong></p>

  <ul>
  <li><b>Definitions</b></li>
  <li>"Inland harbor pleasure boat fee": the rate charged for the right to use public water with a pleasure boat;</li>
  <li>"Pleasure craft": a craft that is used almost entirely over time for non-commercial sailing recreation;</li>
  <li>“Public water”: all waters located within the municipal boundaries of Amsterdam that are navigable or otherwise accessible to the public with or without any restriction;</li>
  <li>“Vignette”: a vignette that is attached in a clearly visible place on the back of the pleasure craft and that can be used to determine whether the Inland Recreational Shipping Dues has been paid for the pleasure craft to which it is attached;</li>
  <li>“Owner”: the person who is liable to pay tax on the basis of Article 3 of the Inland Navigation Passenger Regulation 2020 regulation;</li>
  <li>"Skipper": the person who actually manages a pleasure craft;</li>
  <li>For the other definitions used in these general terms and conditions, reference is made to the definitions referred to in Article 1 of the Inland Navigation Passenger Regulation 2020 regulation.</li>
  </ul>
  <br />
  <ul>
  <li><b>Applicability</b></li>
  <li>These general terms and conditions apply to the payment of the Inland Harbor Dues Pleasure Shipping, as referred to in Article 2 of the Inland Harbor Dues Pleasure Shipping 2020 Regulation.</li>
  <li>All obligations arising from the Inland Waterways Funding Regulation 2020 and the Inland Waterways Regulation 2019 apply in full.</li>
  </ul>
  <br />
  <ul>
  <li><b>Prices</b></li>
  <li>The different rates correspond to the amounts specified in Article 5 of the Inland Navigation Passenger Regulation 2020 regulation.</li>
  <li>The rate of the Inland Harbor Passenger Shipping Fee is payable for the entire calendar year, regardless of the time of commencement.</li>
  <li>The owner of a pleasure boat will be notified at the start of the calendar year about the current rate.</li>
  </ul>
  <br />
  <ul>
  <li><b>Conditions</b></li>
  <li>The owner of a pleasure boat is considered to be:</li>
    <ul>
      <li>provide all necessary personal data, including at least his name, address, email address and telephone number;</li>
      <li>provide all necessary details of the pleasure craft, including at least the name, dimensions and method of propulsion;</li>
      <li>provide photos of the pleasure craft; and</li>
      <li>if applicable, provide a marina document and the name of the marina for the purpose of determining the subscription rate.</li>
    </ul>
  <li>The owner of a pleasure boat agrees to the processing of the personal data referred to in the first paragraph and the data of the pleasure boat for registration purposes.</li>
  <li>The owner of a pleasure boat agrees to the processing of the personal data and data of the pleasure boat referred to in the first paragraph by cameras and sensors located on and near public water. The Amsterdam Policy Framework for CCTV is fully applicable.</li>
  <li>The owner of a pleasure boat is obliged to immediately inform the municipality of Amsterdam of any changes to the information referred to in the first paragraph.</li>
  <li>If the owner of a pleasure boat has intentionally provided incorrect information, as referred to in the first paragraph, the vignette for that pleasure craft is declared invalid and the owner loses the right to use public pleasure water directly or there or mooring indirectly.</li>
  </ul>
  <br />
  <ul>
  <li><b>Boat license</b></li>
  <li>The skipper of a pleasure boat has a boat license 1 if:</li>
    <ul>
    <li>that pleasure craft can travel faster than 20 kilometers per hour through the water;</li>
    <li>that pleasure craft has a length of 15 meters to 25 meters.</li>
    </ul>
  </ul>
  <br />
  <ul>
  <li><b>Payment</b></li>
  <li>The owner of a pleasure craft owes the Inland Harbor Dues Pleasure Craft at the start of the calendar year.</li>
  <li>Payment of the Inland Navigation Passenger Cargo takes place exclusively via:</li>
  <li>iDeal, if the payable rate is paid in one go;</li>
  <li>direct debit if the rate due is paid in different monthly installments before 1 May of the calendar year.</li>
  <li>With regard to payment in installments as referred to in the second paragraph, part b, additional administration and collection costs will be charged.</li>
  <li>If payment in installments as referred to in the second paragraph, part b, can no longer be realized, the owner of a pleasure craft can request a payment arrangement. The conditions applied by the municipality of Amsterdam when making a payment arrangement are fully applicable.</li>
  </ul>
  <br />
  <ul>
  <li><b>Term</b></li>
  <li>The Inland Harbor Passenger Shipping Fee must be paid in full before 1 March of the current calendar year.</li>
  <li>If the Inland Harbor Pleasure Shipping Pleasure is paid in different monthly installments, the Inland Harbor Pleasure Shipping Pleasure must have been paid in full before 1 May of the current calendar year.</li>
  <li>If the Inland Recreational Shipping Dues has not been paid in full before the date referred to in the first or second paragraph, the owner of the pleasure craft will receive a one-time reminder in which he is requested to pay the Inland Recreational Shipping Dues immediately.</li>
  <li>f the Inland Harbor Passenger Shipping Fee has not been paid in full before the date specified in the first or second paragraph, the owner of the pleasure boat will immediately be in default without any further summons or notice of default being required.</li>
  </ul>
  <br />
  <ul>
  <li><b>Collection costs</b></li>
  <li>If the owner of the pleasure boat is in default or in default with regard to the payment of the Inland Harbor Passenger Dues, all reasonable costs for obtaining satisfaction out of court are at the expense of the owner of the pleasure boat.</li>
  <li>If the owner of the pleasure craft remains in default with regard to the timely payment of the Inland Navigation Passenger Port Dues, an additional levy surcharge of 50% will be charged on the still due Inland Harbor Passenger Fun. The additional levy surcharge does not relieve the owner of the recreational craft from the obligation to still pay the full Inland Recreational Craft Harbor Dues. Neither is the additional levy surcharge deducted from the inland navigation pleasure craft due.</li>
  <li>Any legal and execution costs incurred will be borne by the owner of the pleasure craft.</li>
  <li>The owner of the pleasure craft owes interest on the collection costs incurred.</li>
  </ul>
  <br />
  <ul>
  <li><b>Sign out</b></li>
  <li>If the owner of a pleasure boat waives payment of the Inland Harbor Dues Pleasure Navigation, he will indicate this before the start of the new calendar year.</li>
  <li>In the case referred to in the first paragraph, the vignette for that pleasure craft is declared invalid as of the new calendar year and the owner loses the right to use public pleasure water or moor it directly or indirectly with that pleasure craft.</li>
  <li>In the case referred to in the first paragraph, the owner removes the vignette from the recreational craft from the new calendar year and returns the vignette to the municipality of Amsterdam.</li>
  </ul>
  <br />
  <ul>
  <li><b>Restitution</b></li>
  <li>There is no refund of the Inland Navigation Passenger Dues due for that calendar year.</li>
  <li>Partial rejection of general terms and conditions</li>
  </ul>
  <p>If one or more of the provisions of these general terms and conditions are rejected by judicial intervention, the remaining provisions of these general terms and conditions will continue to apply.</p>
  `,
  'extend.step.port.rules': 'Inland port dues regulation',
  'extend.step.port.rules.message': 'Inland port dues regulation message',
  'extend.step.vob.message': 'VOB message',
  'extend.step.payment.mandate.success.message': 'Mandate created successfully',
  'extend.step.payment.mandate.cancel.message': 'cancel message',
  'extend.step.payment.mandate.error.message': 'error message',
  'extend.step.payment.mandate.reject.message': 'reject message',

  'extend.step.payment.direct.cancel.message':
    'Payment was not successful. Try again.',
  'extend.step.payment.direct.error.message':
    'Payment was not successful. Try again.',
  'extend.step.payment.direct.reject.message':
    'Payment was not successful. Try again.',

  'extend.instructions.bullets.one': 'Your BSN number',
  'extend.instructions.bullets.two': 'Your current vignette number',
  'extend.instructions.bullets.three': 'The dimensions of your boat',

  'extend.hotdesk.line':
    'Tel:14020 (vanuit buitenland +3120624111), Email: vaarvignet@amsterdam.nl',
  'extend.abonnment.vignette.message':
    'NB: als u in 2020 geen vignet meer nodig heeft, vink deze toch aan en vul bij Naam jachthaven “Afmelding” in.',
  'extend.payment.instruction.more.context':
    'In the next screen you control the digital authorization. At the beginning of 2021 you will receive a letter in which you can indicate an extension or cancellation',
  'extend.customer.addition.popover.message': 'addition popover message',
  'extend.installment.period': 'Period',
  'extend.installment.amount': 'Amount',
  'extend.installmennt.date': 'Date',
  'extend.installment.48hours': 'within 48 hours',
  'extend.installment.march': '28th of March',
  'extend.installment.april': '28th of April',
  'extend.bank.not.listed':
    'If your bank is not mentioned in the list, please contact 14020.',
  'extend.bank.authorization':
    'If it concerns an account, an authorization is required from all parties.',
  'extend.installment.table.info':
    'The following years the rate will be automatically debited from your account. You will receive a message at the beginning of next year about the new rates, payment options and the option to log in / out',
  'extend.instllament.no.installment':
    'Payment in installments is no longer possible after 25 March',
  'browser.not.supported':
    'To be able to visit this website you must use one of the following web browsers: Chrome, FireFox, Safari and Edge',

  'buy.vignet': 'Apply for a vignette',
  'BHG.vignet': 'Annual vignette',
  'mooring.vignet': 'I want to moor for a day',
  'buy.vignete.instruction.title': 'Apply for an annual vignette',
  'buy.vignet.instruction.one.day.title': 'Apply for a vignette for 1 day',
  'buy.vignete.instruction.content':
    'Below you can see which steps you go through in this form. <br /> And how long it takes to complete the form.',
  'buy.vignet.instruction.subtitle':
    '<subtext>Note: you must complete the form in 1 go!</subtext>',
  'buy.vignet.instruction.contact':
    'Difficulties during the process? Please contact the helpdesk <br /> Helpdesk opening hours 08:00 - 18:00 <br /> Tel: 14020 (from abroad +3120624111), Email: vaarvignet@amsterdam.nl',
  'buy.vinget.instruction.item.1': 'Price calculation of your vignette',
  'buy.vinget.instruction.item.2': 'Create account or login',
  'buy.vinget.instruction.item.3': 'Upload documents (if necessary)',
  'buy.vinget.instruction.item.4': 'Verify your information',
  'buy.vinget.instruction.item.5': 'Issue authorization for direct debit',
  'buy.vinget.instruction.item.6': 'Choose how you want to pay',
  'buy.vinget.instruction.item.7': 'Pay',
  'buy.vinget.instruction.one.day.item.1': 'Enter your personal details',
  'buy.vinget.instruction.one.day.item.2': 'Enter your boat details',
  'buy.vinget.instruction.one.day.item.3': 'Verify your information',
  'buy.vinget.instruction.one.day.item.4': 'Pay vignette',

  'buy.vignet.personal.info.form.title': 'Enter your personal details',
  'buy.vignet.boat.name': 'Name of your boat',
  'buy.vignet.boat.type': 'Boat type',
  'buy.vignet.boat.propulsion': 'Propulsion of your boat',

  'buy.vignet.info.title': 'BHG Information',
  'regular.boat.description': 'Regular boat description',
  'mileu.boat.description': 'Mileu boat description',
  'abonnement.boat.description': 'Abonnement boat description',
  'buy.vignet.tarif.choices.title': 'Price calculation of your vignette',
  'buy.vignet.tarif.choices.instruction.title':
    'To calculate the price of your annual vignette, we need some information from you.',
  'buy.vignet.tarif.choices.instruction.description':
    'How is your boat powered?',
  'buy.vignet.tarif.boat.option.electrical':
    'By an electric motor or without a motor',
  'buy.vignet.tarif.boat.option.other': 'By a petrol engine or diesel engine',
  'buy.vignet.tarif.boat.option.both':
    'With an electric motor and a petrol or diesel engine',
  'buy.vignet.tarif.boat.size': 'What is the size of your boat?',
  'buy.vignet.tarif.place.label':
    'Is your boat in a marina or outside Amsterdam?',
  'buy.vignet.tarif.boat.surface': 'Surface of your boat',
  'buy.vignet.document.tooltip':
    'Is your boat in a marina in or outside Amsterdam? <br />Then you must upload a marina invoice.<br />Is your boat outside of Amsterdam, but not in a marina? <br />Then you must upload a photo of the berth with it visible moored the boat. <br />And a map of the location of your boat.',
  'buy.vignet.click.here': 'click here',
  'buy.vignet.tarif.modal.title': 'Note: you request a white vignette',
  'buy.vignet.tarif.modal.description':
    'Your data shows that you are going to apply for a white vignette. That is a vignette where you are in a marina or outside Amsterdam. You must upload proof of this later in the form. With this vignette you are not allowed to moor in Amsterdam between 11 p.m. and 6 a.m. Do you want to moor at night? Then close this screen and change your details.',
  'buy.vignet.tarif.modal.milieu.description':
    'Your data shows that you are going to apply for a green vignette. This vignette is only valid if your boat has an electric motor or no motor. Does your boat have a petrol or diesel engine? Then close this screen and change your details.',
  'buy.vignet.account.description':
    'Have you previously applied for or extended a vignette? Then you already have an account. <br />Is this your first request? Then you must create an account.',
  'buy.vignet.have.account': 'I already have a BHG account',
  'buy.vignet.no.account': "I don't have a BHG account yet",
  'upload.files': 'Upload documents',
  'upload.files.instruction':
    'You must add the documents that we asked for in the form here. <br />Do you not have these documents? Then you cannot complete the application.',
  'upload.files.message': 'Drag the document (s) here or select your',
  'upload.files.select.files': 'document (s)',
  'request.overview': 'Verify your information',
  'request.overview.title': 'This is the information we have about you:',
  'request.overview.vignet.type': 'Type of vignet',
  'request.overview.drive': 'Drive',
  'request.overview.hunting': 'Hunting ports',
  'request.overview.night': 'Night mooring',
  'request.overview.amount.toPay': 'Amount you have to pay',
  'request.overview.whitevignet.timeswarning':
    'With this vignette you are only allowed to moor between 06.00 and 23.00!',
  'request.overview.checkbox.message':
    'I have entered all data correctly and completely. I understand that an application via the Internet has the same legal status as an application with a written valid signature.',
  'request.overview.checkbox.message.2':
    "I am aware of the rules that apply to Amsterdam's waterways, as they are described in the Inland Harbor Dues Ordinance Recreational Boating and the Inland Waterways Regulation (available at amsterdam.nl/varen). I agree to these rules when renewing or canceling my vignettes.",
  'buy.one.day.vignet.successful.message':
    'We have the digital vignette <br /> sent to your email address',
  'buy.vignet.successful.message':
    'Your application with number <span style="color: #2699FB">{variable}</span> has been well received.<br />We first check the documents you have uploaded. <br />If your documents are approved, <br />you will receive an email with more information about paying.',
  'buy.vignet.success.no.documents.message':
    'Your application with number <span style="color: #2699FB">{variable}</span> has been well received. <br /> You will receive an email from us with more information.',
  'buy.vignet.document.info.title':
    '<strong>You must upload document (s)</strong>',
  'buy.vignet.document.info':
    'To apply for this annual vignette, you must upload documents later in the form. Make sure you have these document (s) at hand.<br />If you do not have these documents, you cannot complete the application.',
  'buy.vignet.tarif.choices.documents.info': 'Some messages',
  'buy.one.day.vignet.date.restriction':
    'You can only apply for the vignette on the day that you also moor. <br /> The vignette is then valid from 00:01 to 23:59. <br />It is not possible to choose a day in the future. <br /> Note: your boat must be less than 10 meters.',
  'buy.vignet.tarif.modal.checkbox': 'I have read the warning and agree.',
  'buy.vignet.payment.instruction.title':
    'Explanation about authorization for direct debit',
  'buy.vignet.payment.instruction.description':
    'At the moment you can choose to pay for your vignette in one go via iDEAL or in installments via direct debit. Next year you can only pay by direct debit. You must now issue an authorization for this. You can then complete your application.',
  'buy.vignet.payment.instruction.date': 'Pay in 2021',
  'buy.vignet.payment.instruction.date.content':
    'With the authorization you give the municipality of Amsterdam permission to automatically debit the inland harbor dues from your account from next year. Do you want to renew your vignette next year? Then you can still choose to pay the amount in one go or in installments. You can of course also cancel your vignette. No money will be debited from your account.',
  'buy.vignet.payment.instruction.date.content.more':
    'In early January 2021 you will receive a letter from us containing information about the new rates. Do you want to renew your vignette in 2021? Then you also get the choice to pay the amount in one go or in installments. You can of course also unsubscribe your vignette. <br />No money will then be debited from your account.',
  'buy.vignet.payment.instruction.issue': 'Issue authorization',
  'buy.vignet.payment.instruction.issue.content':
    'In the next screen you give an authorization to your bank. Then proceed with applying for your 2020 annual vignette.',
  'buy.vignet.payment.choice.description':
    'You can pay your annual vignette in one go via iDeal or in installments via direct debit. Choose how you want to pay below.',
  'buy.vignet.payment.choice.description.disable':
    'From September 29, it is only possible to pay for your 2020 annual vignette in one go.',
  'buy.vignet.payment.once': 'Pay in one go',
  'buy.vignet.payment.installment': 'Pay in installments',
  'buy.vignet.payment.modal.description':
    'Please note: do we still have to check documents or do you opt for Payment in installments? Then your application is immediately completed.<br />You will not be able to return and will later receive an email with information about payment',
  'buy.vignet.account.creation.instruction': 'You are now creating an account.',
  'buy.vignet.upload.documents.lig.info': `<h4><strong>If your boat is in a marina in or outside Amsterdam</strong></h4><span>The marina certificate is an invoice from the marina. This should state that you must pay the marina fee for that year (annual mooring fee mm-yy to mm-yy). The invoice must also state:</span>
                                            <ul><li>the name of the marina</li>
                                            <li>The address of the marina</li>
                                            <li>The date of the invoice</li>
                                            <li>Your own address</li>
                                            </ul>
                                            <h4>If your boat is outside Amsterdam and not in a marina</h4>
                                            <span>Photo of the berth with the boat moored on it and a map of the location</span>`,
  'buy.vignet.tarif.milieu.choice.modal.title':
    'Note: you request a green vignette',
  'buy.vignet.tarif.modal.hybride.title':
    'Please note: you request a yellow vignette',
  'buy.vignet.forgot.password.title':
    'Enter your e-mail address here. A message will be sent to the email address linked to this vignette.',
  'buy.vignet.make.password': 'Make a password',
  'buy.vignet.retype.password': 'Repeat your password',
  'buy.vignet.reset.password': 'Reset password',
  'not.supported.now':
    'It is currently not possible to apply for a day vignette. For more information, see <a href="https://amsterdam.nl/varen">amsterdam.nl/varen</a>',
  'annual.instruction.description': `<h4>Privacy</h4>
    The Municipality of Amsterdam handles your personal data carefully and securely. <br />We adhere to the privacy laws and regulations. This means, for example, that we only use your data for this request and not for anything else. <br /> More information can be found in the <a href="https://www.amsterdam.nl/privacy/specifieke/privacyverklaring-parkeren-verkeer-bouw/verkeersmanagement-gracht-privacy/" target="_blank">Inland Port Dues privacy statement</a>.`,
};
