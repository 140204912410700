import { connect } from 'react-redux';

import { Creators } from 'services/redux/buyVignette/actions';
import PersonalInfoForm from './components';

const mapStateToProps = state => {
  return {
    checkingDay: state.buyVignet.checkingDay,
    checkDayError: state.buyVignet.checkDayError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkDay: data => {
      dispatch(Creators.buyVignetCheckDayRequest(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfoForm);
