import './styles.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import { onDocumentDragOver, onDocumentDrop } from './helpers';

class Dropzone extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener('dragover', onDocumentDragOver);
    document.addEventListener('drop', onDocumentDrop);
  }

  componentWillUnmount() {
    document.removeEventListener('dragover', onDocumentDragOver);
    document.removeEventListener('drop', onDocumentDrop);
  }

  handleDrop = e => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;
      this.props.onFilesDrop(this.props.name, files);
    }
  };

  handleClick = () => {
    this.props.onClick(this.props.name);
  };

  render() {
    return (
      <Row
        className="dropzone-container"
        onDrop={this.handleDrop}
        onClick={this.handleClick}
      >
        <Row className="dropzone-content">{this.props.children}</Row>

        <div className="dropzone-hint">
          <p className="dropzone-hint-text">{this.props.text}</p>
        </div>
      </Row>
    );
  }
}

Dropzone.defaultProps = {
  text: 'Drop or click',
  name: 'files',
};

Dropzone.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  onFilesDrop: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

export default Dropzone;
