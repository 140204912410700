import React from 'react';

import Instruction, { InitializeDay } from './Instructions';
import VignetInfo from './VignetInfo';
import TarifChoices from './TarifChoices';
import ChooseAccount from './ChooseAccount';
import { BUY_VIGNNETTE_ROUTE } from 'constants/index';
import Account from 'containers/ExtendVignetteSteps/Steps/Account/components';
import Login from './Login';
import CustomerInfo from 'containers/ExtendVignetteSteps/Steps/CustomerInfo/components';
import UploadFiles from './UploadFiles';
import Overview from './Overview';
import OverViewOneday from './Overview/components';
import BuyVignetSuccessful from './Success/components';
import OneDayTarifChoiceForm from './OneDayTarifChoices';
import PaymentInstruction from './PaymentInstruction';

import { history } from 'util/helpers/history';
import DirectPaymentBank from 'containers/ExtendVignetteSteps/Steps/DirectPaymentBank/components';
import MandateBankForm from 'containers/ExtendVignetteSteps/Steps/MandateBankLists/components';
import PaymentChoice from './PaymentChoice';
import { FormattedMessage } from 'react-intl';
import { formatOverviewData } from 'util/helpers/normalizer';

class BuyVignetteSteps extends React.Component {
  getCurrentStepComponent = () => {
    let component = null;
    switch (this.props.route) {
      case BUY_VIGNNETTE_ROUTE.INSTRUCTION_ROUTE:
        component = <Instruction />;
        break;

      case BUY_VIGNNETTE_ROUTE.VIGNET_INFO_ROUTE:
        component = <VignetInfo />;
        break;

      case BUY_VIGNNETTE_ROUTE.TARIF_CHOICES_ROUTE:
        component = <TarifChoices />;
        break;

      case BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE:
        component = <ChooseAccount />;
        break;

      case BUY_VIGNNETTE_ROUTE.ACCOUNT_CREATION_ROUTE:
        component = (
          <Account
            onPrevious={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE)
            }
            onSubmit={values => this.props.createAccount(values)}
            isLoading={this.props.isCreatingAccount}
            error={this.props.accountError}
            current={1}
            steps={7}
            id="buy.vignete.instruction.title"
            instruction="buy.vignet.account.creation.instruction"
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.LOGIN_ROUTE:
        component = <Login />;
        break;

      case BUY_VIGNNETTE_ROUTE.PERSONAL_DETAILS_ROUTE:
        component = (
          <CustomerInfo
            title="buy.vignete.instruction.title"
            getOwner={() => this.props.getOwner()}
            customer={this.props.customer}
            onPrevious={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.CHOOSE_ACCOUNT_ROUTE)
            }
            updateOwner={data => this.props.updateCustomer(data)}
            isUpdating={this.props.isUpdatingOwner}
            updateError={this.props.updateError}
            current={2}
            steps={7}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.UPLOAD_FILES_ROUTE:
        component = <UploadFiles />;
        break;

      case BUY_VIGNNETTE_ROUTE.OVERVIEW_ROUTE:
        component = (
          <Overview
            current={4}
            steps={7}
            title="buy.vignete.instruction.title"
            onPrevious={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.UPLOAD_FILES_ROUTE)
            }
            onNext={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.PAYMENT_AGREEMENT)
            }
            customer={formatOverviewData(this.props.overview).customerInfo}
            vignetInfo={formatOverviewData(this.props.overview).vignetData}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.PAYMENT_AGREEMENT:
        component = <PaymentInstruction />;
        break;

      case BUY_VIGNNETTE_ROUTE.PAYMENT_SUCCESSFUL_ROUTE:
        component = (
          <BuyVignetSuccessful
            id="buy.vignet.successful.message"
            titleId="buy.vignete.instruction.title"
            variable={sessionStorage.getItem('request_number')}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.PAYMENT_SUCCESSFUL_ROUTE_NO_DOCUMENT:
        component = (
          <BuyVignetSuccessful
            id="buy.vignet.success.no.documents.message"
            titleId="buy.vignete.instruction.title"
            variable={sessionStorage.getItem('request_number')}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.DIRECT_PAYMENT:
        component = (
          <DirectPaymentBank
            id="buy.vignete.instruction.title"
            getBankLists={() => this.props.getBankLists({ list_type: 'ideal' })}
            banks={this.props.banks}
            isFetchingBanks={this.props.isFetchingBanks}
            postDirect={data => this.props.createDirect(data)}
            isNextLoading={this.props.isMakingAnnualPayment}
            onPrevious={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.PAYMENT_CHOICE)
            }
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.MANDATE_PAYMENT:
        component = (
          <MandateBankForm
            id="buy.vignete.instruction.title"
            getBankLists={() =>
              this.props.getBankLists({ list_type: 'mandate' })
            }
            banks={this.props.banks}
            isFetchingBanks={this.props.isFetchingBanks}
            isFetchingMandate={this.props.isCreatingMandate}
            postMandate={data => this.props.createMandate(data)}
            onPrevious={() =>
              this.props.updateRoute(BUY_VIGNNETTE_ROUTE.PAYMENT_AGREEMENT)
            }
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.PAYMENT_CHOICE:
        component = <PaymentChoice />;
        break;

      // ONE DAY VIGNET
      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.INSTRUCTION_ROUTE:
        component = <InitializeDay />;
        break;

      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.PERSONAL_INFO_ROUTE:
        component = (
          <CustomerInfo
            current={0}
            steps={4}
            getOwner={() => console.log()}
            customer={{}}
            onPrevious={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.INSTRUCTION_ROUTE,
              )
            }
            createDetails={data =>
              // this.props.updateRoute(
              //   BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.TARIF_CHOICE_ROUTE,
              // )
              this.props.checkDay(data)
            }
            isUpdating={this.props.checkingDay}
            updateError={this.props.checkDayError}
            title="buy.vignet.instruction.one.day.title"
            formTitle="buy.vignet.personal.info.form.title"
            newDayVignet={true}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.TARIF_CHOICE_ROUTE:
        component = <OneDayTarifChoiceForm />;
        break;

      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.OVERVIEW_ROUTE:
        component = (
          <Overview
            onPrevious={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.TARIF_CHOICE_ROUTE,
              )
            }
            onNext={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.DIRECT_PAYMENT,
              )
            }
            current={2}
            steps={4}
            title="buy.vignet.instruction.one.day.title"
            customer={JSON.parse(sessionStorage.getItem('customer_info'))}
            vignetInfo={JSON.parse(sessionStorage.getItem('vignet_info'))}
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.DIRECT_PAYMENT:
        component = (
          <DirectPaymentBank
            current={3}
            steps={4}
            id="buy.vignet.instruction.one.day.title"
            getBankLists={() => this.props.getBankLists({ list_type: 'ideal' })}
            banks={this.props.banks}
            isFetchingBanks={this.props.isFetchingBanks}
            isNextLoading={this.props.isMakingDayPayment}
            postDirect={data => this.props.makeDayPayment(data)}
            onPrevious={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.OVERVIEW_ROUTE,
              )
            }
          />
        );
        break;

      case BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.SUCCSESS_ROUTE:
        component = (
          <BuyVignetSuccessful
            titleId="buy.vignet.instruction.one.day.title"
            id="buy.one.day.vignet.successful.message"
          />
        );
        break;

      default:
        //history.location.state = {};
        component = <Instruction />;
        break;
    }

    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.one_day
    ) {
      history.location.state = {};
      component = <InitializeDay />;
    }

    if (history && history.location && history.location.state) {
      if (history.location.state.route === 'one-day') {
        component = (
          <BuyVignetSuccessful
            titleId="buy.vignet.instruction.one.day.title"
            id="buy.one.day.vignet.successful.message"
          />
        );
      } else if (history.location.state.route === 'one-day-failed') {
        component = (
          <Overview
            onPrevious={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.TARIF_CHOICE_ROUTE,
              )
            }
            onNext={() =>
              this.props.updateRoute(
                BUY_VIGNNETTE_ROUTE.ONE_DAY_VIGNET.DIRECT_PAYMENT,
              )
            }
            current={2}
            steps={4}
            title="buy.vignet.instruction.one.day.title"
            customer={JSON.parse(sessionStorage.getItem('customer_info'))}
            vignetInfo={JSON.parse(sessionStorage.getItem('vignet_info'))}
            bankError={<FormattedMessage id={history.location.state.id} />}
          />
        );
      } else if (history.location.state.route === 'mandate') {
        component = <PaymentChoice />;
      } else if (history.location.state.route === 'mandate-failed') {
        component = (
          <PaymentInstruction mandateResponseMsg={history.location.state.id} />
        );
      } else if (history.location.state.route === 'direct') {
        component = (
          <BuyVignetSuccessful
            id="buy.vignet.success.no.documents.message"
            titleId="buy.vignete.instruction.title"
            variable={sessionStorage.getItem('request_number')}
          />
        );
      } else if (history.location.state.route === 'direct-failed') {
        component = (
          <PaymentChoice mandateResponseMsg={history.location.state.id} />
        );
      }
    }

    return component;
  };

  render() {
    return (
      <div className="activate-vignette-steps">
        {this.getCurrentStepComponent()}
      </div>
    );
  }
}

export default BuyVignetteSteps;
