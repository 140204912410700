import './index.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { Steps, Row, Col, Card, Button, Icon, Spin } from 'antd';
import Stepper from 'components/Steppers';
import Title from 'components/Title';
import { FormattedMessage } from 'react-intl';

export const Header = ({ id, steps: stepsCount, current, ...props }) => {
  const { xs, sm, md, lg, xl, xxl } = props;
  const steps =
    typeof stepsCount === 'number' ? Array.from(Array(stepsCount)) : stepsCount;

  return (
    <Row type="flex" justify="center" className="step-card-header">
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
        <Col md={24} xl={13}>
          {id && (
            <h1 className="heading">
              <FormattedMessage id={id} />
            </h1>
          )}
        </Col>
        <Col md={24} xl={11}>
          <Row type="flex">
            {steps && (
              <Steps current={current} direction="horizontal" className="steps">
                {steps.map((_, index) => (
                  <Steps.Step key={index} className="step" />
                ))}
              </Steps>
            )}
          </Row>
        </Col>
      </Col>
    </Row>
  );

  return (
    <Row type="flex" justify="center" className="step-card-header">
      <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
        <div className="title-wrapper">
          {id && <Title id={id} />}
          {steps ? (
            <React.Fragment>
              <div className="step-wrapper">
                <Stepper numberOfSteps={steps} currentStep={current} />
              </div>
            </React.Fragment>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export const Content = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <Row type="flex" justify="center">
        <Spin />
      </Row>
    );
  }

  return children;
};

export const Body = ({
  isLoading,
  children,
  onPrevious,
  onNext,
  isNextLoading,
  isNextDisabled,
  contactDetails,
  moreDetails,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <Row type="flex" justify="center" className="step-card-body">
      <Col
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
        className="step-col"
      >
        <Card>
          <Content isLoading={isLoading}>{children}</Content>
          <Actions
            onPrevious={onPrevious}
            onNext={onNext}
            isNextLoading={isNextLoading}
            isNextDisabled={isNextDisabled}
            contactDetails={contactDetails}
            moreDetails={moreDetails}
          />
        </Card>
      </Col>
    </Row>
  );
};

export const Actions = ({
  contactDetails,
  moreDetails,
  onPrevious,
  onNext,
  isNextLoading,
  isNextDisabled,
}) => {
  return (
    <Col span={24} className="step-card-actions">
      <Col sm={0} lg={0} md={0}>
        {moreDetails}
        {contactDetails && (
          <>
            <p>
              <FormattedMessage id={contactDetails} />
              <br />
              <FormattedMessage id="extend.step.verification.contact.number" />
              <br />
              <FormattedMessage id="extend.hotdesk.line" />
            </p>
          </>
        )}
      </Col>
      <Col span={4} lg={4} sm={12} xs={12}>
        {onPrevious && (
          <Button className="prev-btn" type="default" onClick={onPrevious}>
            <Icon type="arrow-left" />
            <span className="nav-arrow-text">
              <FormattedMessage id="BACK" />
            </span>
          </Button>
        )}
      </Col>
      <Col span={16} align="center" className="small-screen">
        {moreDetails}
        {contactDetails && (
          <>
            <p>
              <FormattedMessage id={contactDetails} />
              <br />
              <FormattedMessage id="extend.step.verification.contact.number" />
              <br />
              <FormattedMessage id="extend.hotdesk.line" />
            </p>
          </>
        )}
      </Col>
      <Col
        span={4}
        align="end"
        lg={4}
        sm={12}
        xs={12}
        style={{ textAlign: 'end' }}
      >
        {onNext && (
          <Button
            className="next-btn"
            type="primary"
            onClick={onNext}
            disabled={isNextDisabled}
            loading={isNextLoading}
          >
            <span className="nav-arrow-text">
              <FormattedMessage id="NEXT" />
            </span>
            <Icon type="arrow-right" />
          </Button>
        )}
      </Col>
    </Col>
  );
};

export const StepCard = ({
  id,
  contactDetails,
  moreDetails,
  steps,
  current,
  className,
  children,
  onPrevious,
  onNext,
  isLoading,
  isNextLoading,
  isNextDisabled,

  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
}) => {
  return (
    <Row className={`step-card-container ${className}`}>
      <Header
        id={id}
        steps={steps}
        current={current}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
      />
      <Body
        isLoading={isLoading}
        isNextLoading={isNextLoading}
        isNextDisabled={isNextDisabled}
        onPrevious={onPrevious}
        onNext={onNext}
        contactDetails={contactDetails}
        moreDetails={moreDetails}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}
      >
        {children}
      </Body>
    </Row>
  );
};

StepCard.defaultProps = {
  className: '',

  xs: 24,
  sm: 22,
  md: 12,
  lg: 16,
};

StepCard.propTypes = {
  id: PropTypes.string,
  steps: PropTypes.number,
  current: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isNextLoading: PropTypes.bool,
  isNextDisabled: PropTypes.bool,

  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
};

export default StepCard;
