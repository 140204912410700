import en from './en';
import nl from './nl';

// var locales = new Proxy(
//   {
//     // en-US
//     en: en,
//     // nl-NL
//     nl: nl,
//   },
//   {
//     get(locales, language) {
//       if (language.startsWith('en')) {
//         return locales['en'];
//       } else if (language.startsWith('nl')) {
//         return locales['nl'];
//       }

//       return;
//     },
//   },
// );

const locales = {
  // en-US
  en: en,
  // nl-NL
  nl: nl,
};

export default locales;
