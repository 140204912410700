import React from 'react';
import { history } from 'util/helpers/history';
import { formatOverviewData } from 'util/helpers/normalizer';
import Overview from 'containers/BuyVignetteSteps/Steps/Overview/components';

const AnnualOverview = props => {
  return (
    <Overview
      current={3}
      steps={7}
      title="buy.vignete.instruction.title"
      onPrevious={() => history.goBack()}
      onNext={() =>
        history.push('/buy/vignette/annual/payment-instruction/details')
      }
      customer={formatOverviewData(props.overview).customerInfo}
      vignetInfo={formatOverviewData(props.overview).vignetData}
      getOverview={props.getOverview}
      isGettingOverview={props.isGettingOverview}
    />
  );
};

export default AnnualOverview;
