import { connect } from 'react-redux';
import { Creators } from '../../services/redux/auth/actions';
import ActivateVignette from './components';
import { history } from 'util/helpers/history';

const mapStateToProps = state => {
  const { isloading, error, user } = state.auth;
  return {
    isloading,
    error,
    user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToActivateVignetteSteps: () => {
      history.push('/activate/vignette/steps');
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateVignette);
