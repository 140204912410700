import { createActions } from 'reduxsauce';

export const { Types, Creators } = createActions(
  {
    extendGetCustomerRequest: ['customer_id'],
    extendGetCustomerSuccess: ['responseData'],
    extendGetCustomerFailure: ['error'],

    extendUpdateCustomerRequest: ['data'],
    extendUpdateCustomerSuccess: ['responseData'],
    extendUpdateCustomerFailure: ['error'],

    extendUpdateCredentialsRequest: ['email', 'password'],
    extendUpdateCredentialsSuccess: ['responseData'],
    extendUpdateCredentialsFailure: ['error'],

    extendGetPaymentInstallmentRequest: [],
    extendGetPaymentInstallmentSuccess: ['responseData'],
    extendGetPaymentInstallmentFailure: ['error'],

    extendGetPaymentBankListsRequest: ['list_type'],
    extendGetPaymentBankListsSuccess: ['responseData'],
    extendGetPaymentBankListsFailure: ['error'],

    extendGetPaymentMandateRequest: ['issuer'],
    extendGetPaymentMandateSuccess: ['responseData'],
    extendGetPaymentMandateFailure: ['error'],

    extendGetPaymentDirectRequest: ['issuer'],
    extendGetPaymentDirectSuccess: ['responseData'],
    extendGetPaymentDirectFailure: ['error'],

    extendUpdateObjectLocationRequest: ['object_location'],
    extendUpdateObjectLocationSuccess: ['responseData'],
    extendUpdateObjectLocationFailure: ['error'],

    extendPaymentHasMandateRequest: [],
    extendPaymentHasMandateSuccess: ['responseData'],
    extendPaymentHasMandateFailure: ['error'],

    extendCreateInstallmentsRequest: [],
    extendCreateInstallmentsSuccess: ['responseData'],
    extendCreateInstallmentsFailure: ['error'],
  },
  {},
);
