import React from 'react';
import Instruction from 'components/Instruction';
import { history } from 'util/helpers/history';
import { FormattedHTMLMessage } from 'react-intl';
import { Card, Row, Col } from 'antd';
import Title from 'components/Title';

// const InitializeDayExtension = props => {
//   return (
//     // <Instruction
//     //   header={'buy.vignet.instruction.one.day.title'}
//     //   content={'buy.vignete.instruction.content'}
//     //   subheader={'buy.vignet.instruction.subtitle'}
//     //   messages={[
//     //     'buy.vinget.instruction.one.day.item.1',
//     //     'buy.vinget.instruction.one.day.item.2',
//     //     'buy.vinget.instruction.one.day.item.3',
//     //     'buy.vinget.instruction.one.day.item.4',
//     //   ]}
//     //   iconURL={require('assets/10_minutes.svg')}
//     //   iconStyle={{ height: 120, width: 120, top: '50%', position: 'absolute' }}
//     //   onStart={() => history.push('/buy/vignette/one-day/personal')}
//     // />
//     <p>
//       <FormattedHTMLMessage id="not.supported.now" />
//     </p>
//   );
// };

const InitializeDayExtension = props => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: '90vh' }}
    >
      <Col xs={24} md={22} sm={24} sm={24} lg={16} className="mobile-wrapper">
        <Title id="buy.vignet.instruction.one.day.title" />
        <Card align="center">
          <div className="sub-title-wrapper">
            <p className="bold-sub-title">
              <FormattedHTMLMessage id="not.supported.now" />
            </p>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default InitializeDayExtension;
